export default {
  headers: [
    {
      id: 'mainHeader',
      logo: {
        alt: 'remax logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/ugljan/logo.png',
        url: 'https://www.colohomesforsale.com/',
      },
      breakpoint: 1355,
      desktopMenu: {
        type: 'default',
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-ugljan-desktop-header-logo-container',
          imageClassName: 'on-ugljan-desktop-header-logo',
        },
        headerClassName: 'on-ugljan-desktop-header',
        headerSectionsClassName: 'on-ugljan-desktop-header-sections',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '' || null,
        itemClassName: 'on-ugljan-desktop-item',
        menuClassName: 'on-ugljan-desktop-menu',
        menuLabelActiveClassName: 'on-ugljan-active-label',
        menuItemClassName: 'on-ugljan-desktop-item-folder',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'ugljan-listings-item',
            content: 'LISTINGS',
            menuItems: [
              {
                id: 'ugljan-listings-item-one',
                content: 'All Listings',
                url: 'https://www.colohomesforsale.com/listing',
              },
              {
                id: 'ugljan-listings-item-two',
                content: 'Featured Listings',
                url: 'https://www.colohomesforsale.com/featured-listing',
              },
            ],
          },
          {
            id: 'ugljan-selling-item',
            content: 'SELLING',
            menuItems: [
              {
                id: 'ugljan-selling-item-one',
                content: 'Sell My Home',
                url: 'https://www.colohomesforsale.com/sell',
              },
              {
                id: 'ugljan-selling-item-two',
                content: 'Home Evaluation',
                url: 'https://www.colohomesforsale.com/evaluation',
              },
            ],
          },
          {
            id: 'ugljan-about-item',
            content: 'ABOUT',
            url: 'https://www.colohomesforsale.com/about',
          },
          {
            id: 'ugljan-neighborhoods-item',
            content: 'NEIGHBORHOODS',
            menuItems: [
              {
                id: 'ugljan-header-neighborhoods-item-zurchers',
                content: 'ZURCHERS',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130541/Zurchers',
              },
              {
                id: 'ugljan-header-neighborhoods-item-yacovetta',
                content: 'YACOVETTA',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128429/Yacovetta',
              },
              {
                id: 'ugljan-header-neighborhoods-item-woodrim-crossing',
                url: 'www.colohomesforsale.com/neighborhood-detail/127093/Woodrim-Crossing',
                content: 'WOODRIM CROSSING',
              },
              {
                id: 'ugljan-header-neighborhoods-item-woodgate',
                content: 'WOODGATE',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128484/Woodgate',
              },
              {
                id: 'ugljan-header-neighborhoods-item-windsor',
                content: 'WINDSOR AT MEADOW HILLS',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130810/Windsor-at-Meadow-Hills"',
              },
              {
                id: 'ugljan-header-neighborhoods-item-windsor-park',
                content: 'WINDSOR PARK',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131254/Windsor-Park',
              },
              {
                id: 'ugljan-header-neighborhoods-item-windmill',
                content: 'WINDMILL CREEK RESERVE',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129611/Windmill-Creek-Reserve',
              },
              {
                id: 'ugljan-header-neighborhoods-item-',
                content: 'WINDCREEK CONDOMINIUMS',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130978/Windcreek-Condominiums',
              },
              {
                id: 'ugljan-header-neighborhoods-item-winchester',
                content: 'WINCHESTER',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128291/Winchester',
              },
              {
                id: 'ugljan-header-neighborhoods-item-willowridge',
                content: 'WILLOWRIDGE TOWNHOMES',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130058/Willowridge-Townhomes',
              },
              {
                id: 'ugljan-header-neighborhoods-item-willow-trace',
                content: 'WILLOW TRACE',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130794/Willow-Trace',
              },
              {
                id: 'ugljan-header-neighborhoods-item-willow-park',
                content: 'WILLOW PARK',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130472/Willow-Park',
              },
              {
                id: 'ugljan-header-neighborhoods-item-wildwood',
                content: 'WILDWOOD',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129027/Wildwood',
              },
              {
                id: 'ugljan-header-neighborhoods-item-wilco',
                content: 'WILCO',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127978/Wilco',
              },
              {
                id: 'ugljan-header-neighborhoods-item-wikiup',
                content: 'WIKIUP',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131232/Wikiup',
              },
              {
                id: 'ugljan-header-neighborhoods-item-wheatlands',
                content: 'WHEATLANDS',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131495/Wheatlands',
              },
              {
                id: 'ugljan-header-neighborhoods-item-waters-edge',
                content: 'WATERS EDGE',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127251/Waters-Edge',
              },
              {
                id: 'ugljan-header-neighborhoods-item-waterfield',
                content: 'WATERFIELD COURT',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131549/Waterfield-Court',
              },
              {
                id: 'ugljan-header-neighborhoods-item-virginia-heights',
                content: 'VIRGINIA HEIGHTS',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131763/Virginia-Heights',
              },
              {
                id: 'ugljan-header-neighborhoods-item-village-east',
                content: 'VILLAGE EAST',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128497/Village-East',
              },
              {
                id: 'ugljan-header-neighborhoods-item-victory-crossing',
                content: 'VICTORY CROSSING',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131734/Victory-Crossing',
              },
              {
                id: 'ugljan-header-neighborhoods-item-victory-crossing-north',
                content: 'VICTORY CROSSING NORTH',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127089/Victory-Crossing-North',
              },
              {
                id: 'ugljan-header-neighborhoods-item-victoria',
                content: 'VICTORIA CROSSING',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131654/Victoria-Crossing',
              },
              {
                id: 'ugljan-header-neighborhoods-item-victoria-ii',
                content: 'VICTORIA CROSSING II',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129747/Victoria-Crossing-II',
              },
              {
                id: 'ugljan-header-neighborhoods-item-valley',
                content: 'VALLEY CLUB ACRES',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132486/Valley-Club-Acres',
              },
              {
                id: 'ugljan-header-neighborhoods-item-utah',
                content: 'UTAH PARK',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128318/Utah-Park',
              },
              {
                id: 'ugljan-header-neighborhoods-item-tuscany',
                content: 'Tuscany',
                url: '"https://www.colohomesforsale.com/neighborhood-detail/128087/Tuscany',
              },
              {
                id: 'ugljan-header-neighborhoods-item-turnberry',
                content: 'Turnberry',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132262/Turnberry',
              },
              {
                id: 'ugljan-header-neighborhoods-item-trail-ridge',
                content: 'trail ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827157/trail-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-item-traditions',
                content: 'Traditions',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128510/Tradition',
              },
              {
                id: 'ugljan-header-neighborhoods-item-town-center-terrace',
                content: 'Town Center Terrace',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132016/Town-Center-Terrace',
              },
              {
                id: 'ugljan-header-neighborhoods-item-town-&-country',
                content: 'town & country',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827154/town-&-country',
              },
              {
                id: 'ugljan-header-neighborhoods-item-tower-triangle',
                content: 'tower triangle',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827153/tower-triangle',
              },
              {
                id: 'ugljan-header-neighborhoods-item-tower-ridge',
                content: 'tower ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827152/tower-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-item-tower-park',
                content: 'tower park',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827151/tower-park',
              },
              {
                id: 'ugljan-header-neighborhoods-item-tower-green',
                content: 'tower green',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827150/tower-green',
              },
              {
                id: 'ugljan-header-neighborhoods-item-topaz-at-the-mall',
                content: 'topaz at the mall',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827149/topaz-at-the-mall',
              },
              {
                id: 'ugljan-header-neighborhoods-item-tollgate-overlook',
                content: 'tollgate overlook',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827148/tollgate-overlook',
              },
              {
                id: 'ugljan-header-neighborhoods-item-tollgate-crossing',
                content: 'tollgate crossing',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827147/tollgate-crossing',
              },
              {
                id: 'ugljan-header-neighborhoods-item-thunderbird-estates',
                content: 'thunderbird estates',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827146/thunderbird-estates',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-vistas-at-saddle-rock',
                content: 'the vistas at saddle rock',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827145/the-vistas-at-saddle-rock',
              },
              {
                id: 'ugljan-header-neighborhoods-itemthe-vistas-at-meadow-hills-',
                content: 'the vistas at meadow hills',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827144/the-vistas-at-meadow-hills',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-villas',
                content: 'the villas',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827143/the-villas',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-villas-at-great-plains-park',
                content: 'the villas at great plains park',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827142/the-villas-at-great-plains-park',
              },
              {
                id: 'ugljan-header-neighborhoods-item-',
                content: 'the villas at cherry creek',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827141/the-villas-at-cherry-creek',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-timbers',
                content: 'the timbers',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827140/the-timbers',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-shores',
                content: 'the shores',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827139/the-shores',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-savoyat-dayton-station',
                content: 'the savoyat dayton station',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827138/the-savoyat-dayton-station',
              },
              {
                id: "ugljan-header-neighborhoods-item-the-sanctuary-at-tallyn's-reach",
                content: "the sanctuary at tallyn's reach",
                url: "https://www.colohomesforsale.com/neighborhoods/153827137/the-sanctuary-at-tallyn's-reach",
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-orchard',
                content: 'the orchard',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827136/the-orchard',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-landing-at-cherry-creek',
                content: 'the landing at cherry creek',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827135/the-landing-at-cherry-creek',
              },
              {
                id: 'ugljan-header-neighborhoods-item-',
                content: 'the knolls at park view',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827134/the-knolls-at-park-view',
              },
              {
                id: 'ugljan-header-neighborhoods-item-',
                content: 'the hills at piney creek',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827133/the-hills-at-piney-creek',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-highlands-at-piney-creek',
                content: 'the highlands at piney creek',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827132/the-highlands-at-piney-creek',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-grove',
                content: 'the grove',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827131/the-grove',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-greens',
                content: 'the greens',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827130/the-greens',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-farm',
                content: 'the farm',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827129/the-farm',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-dam-west',
                content: 'the dam west',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827128/the-dam-west',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-dam-east',
                content: 'the dam east',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827127/the-dam-east',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-colony-at-cherry-creek',
                content: 'the colony at cherry creek',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827126/the-colony-at-cherry-creek',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-chateaux',
                content: 'the chateaux',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827125/the-chateaux',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-bluffs',
                content: 'the bluffs',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827124/the-bluffs',
              },
              {
                id: 'ugljan-header-neighborhoods-item-the-aspens',
                content: 'the aspens',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827123/the-aspens',
              },
              {
                id: 'ugljan-header-neighborhoods-item-telegraph-hill-ii',
                content: 'Telegraph Hill II',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827122/telegraph-hill-ii',
              },
              {
                id: 'ugljan-header-neighborhoods-item-tallyns-reach',
                content: "Tallyn's Reach",
                url: "https://www.colohomesforsale.com/neighborhoods/153827121/tallyn's-reach",
              },
              {
                id: 'ugljan-header-neighborhoods-item-swedish-gardens',
                content: 'Swedish gardens',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827120/swedish-gardens',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sunstone-townhomes',
                content: 'sunstone townhomes',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827119/sunstone-townhomes',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sunstone-north',
                content: 'sunstone north',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827118/sunstone-north',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sunridge',
                content: 'sunridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827117/sunridge',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sunny-vale',
                content: 'sunny vale',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827116/sunny-vale',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sunny-slope',
                content: 'sunny slope',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827115/sunny-slope',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sunlite',
                content: 'sunlite',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827114/sunlite',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sunlite-ridge',
                content: 'sunlite ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827113/sunlite-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sunburst',
                content: 'sunburst',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827112/sunburst',
              },
              {
                id: 'ugljan-header-neighborhoods-item-summit-at-smoky-hill',
                content: 'summit at smoky hill',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827111/summit-at-smoky-hill',
              },
              {
                id: 'ugljan-header-neighborhoods-item-summit-park',
                content: 'summit park',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827110/summit-park',
              },
              {
                id: 'ugljan-header-neighborhoods-item-summerglen',
                content: 'summerglen',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827109/summerglen',
              },
              {
                id: 'ugljan-header-neighborhoods-item-summerfield-villas',
                content: 'summerfield villas',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827108/summerfield-villas',
              },
              {
                id: 'ugljan-header-neighborhoods-item-summerhill',
                content: 'summerhill',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827107/summerhill',
              },
              {
                id: 'ugljan-header-neighborhoods-item-summer-valley',
                content: 'summer-valley',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827106/summer-valley',
              },
              {
                id: 'ugljan-header-neighborhoods-item-s',
                content: 'summer lakes',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827105/summer-lakes',
              },
              {
                id: 'ugljan-header-neighborhoods-item-summer-breeze',
                content: 'summer breeze',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827104/summer-breeze',
              },
              {
                id: 'ugljan-header-neighborhoods-item-strawberry',
                content: 'strawberry',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827103/strawberry',
              },
              {
                id: 'ugljan-header-neighborhoods-item-stonetree',
                content: 'stonetree',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827102/stonetree',
              },
              {
                id: 'ugljan-header-neighborhoods-item-stonehouse-farm',
                content: 'stonehouse farm',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827101/stonehouse-farm',
              },
              {
                id: 'ugljan-header-neighborhoods-item-stone-ridge-park',
                content: 'stone ridge park',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827100/stone-ridge-park',
              },
              {
                id: 'ugljan-header-neighborhoods-item-stone-cliff',
                content: 'stone cliff',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827099/stone-cliff',
              },
              {
                id: 'ugljan-header-neighborhoods-item-stone-canyon',
                content: 'stone canyon',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827098/stone-canyon',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sterling-pointe',
                content: 'sterling pointe',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827097/sterling-pointe',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sterling-hills',
                content: 'sterling hills',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827096/sterling-hills',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sterling-commons',
                content: 'sterling commons',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827095/sterling-commons',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sterling-commons-ii',
                content: 'sterling commons ii',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827094/sterling-commons-ii',
              },
              {
                id: 'ugljan-header-neighborhoods-item-star-pass-of-saddle-rock',
                content: 'star pass of saddle rock',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827093/star-pass-of-saddle-rock',
              },
              {
                id: 'ugljan-header-neighborhoods-item-stapleton-industrial-park',
                content: 'stapleton industrial park',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827092/stapleton-industrial-park',
              },
              {
                id: 'ugljan-header-neighborhoods-item-stage-run',
                content: 'stage run',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827091/stage-run',
              },
              {
                id: 'ugljan-header-neighborhoods-item-spruce-neighborhood-at-copperleaf',
                content: 'spruce neighborhood at copperleaf',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827090/spruce-neighborhood-at-copperleaf',
              },
              {
                id: 'ugljan-header-neighborhoods-item-spring-creek-meadows',
                content: 'spring creek meadows',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827089/spring-creek-meadows',
              },
              {
                id: 'ugljan-header-neighborhoods-item-southshore',
                content: 'southshore',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827088/southshore',
              },
              {
                id: 'ugljan-header-neighborhoods-item-southeast-crossing',
                content: 'southeast crossing',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827087/southeast-crossing',
              },
              {
                id: 'ugljan-header-neighborhoods-item-south-rose-hill',
                content: 'south rose hill',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827086/south-rose-hill',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sorrel-ranch',
                content: 'sorrel ranch',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827085/sorrel-ranch',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sonoma-resort-at-saddle-rock',
                content: 'sonoma resort at saddle rock',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827084/sonoma-resort-at-saddle-rock',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sommerset-gardens',
                content: 'sommerset gardens',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827083/sommerset-gardens',
              },
              {
                id: 'ugljan-header-neighborhoods-item-somerset-village',
                content: 'somerset village',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827082/somerset-village',
              },
              {
                id: 'ugljan-header-neighborhoods-item-smoky-ridge',
                content: 'smoky ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827081/smoky-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-item-smoky-hill',
                content: 'smoky hill',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827080/smoky-hill',
              },
              {
                id: 'ugljan-header-neighborhoods-item-skyline-terrace',
                content: 'Skyline terrace',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827079/skyline-terrace',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sienna',
                content: 'sienna',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827078/sienna',
              },
              {
                id: 'ugljan-header-neighborhoods-item-siena',
                content: 'Siena',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827077/siena',
              },
              {
                id: 'ugljan-header-neighborhoods-item-side-creek',
                content: 'side creek',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827076/side-creek',
              },
              {
                id: 'ugljan-header-neighborhoods-item-shiloh-ridge',
                content: 'shiloh ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827075/shiloh-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-item-shenandoah',
                content: 'shenandoah',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827074/shenandoah',
              },
              {
                id: 'ugljan-header-neighborhoods-item-shamrock-park',
                content: 'shamrock park',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827073/shamrock-park',
              },
              {
                id: 'ugljan-header-neighborhoods-item-shadow-creek',
                content: 'shadow creek',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827072/shadow-creek',
              },
              {
                id: 'ugljan-header-neighborhoods-item-seven-lakes',
                content: 'seven lakes',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827071/seven-lakes',
              },
              {
                id: 'ugljan-header-neighborhoods-item-seven-hills',
                content: 'seven hills',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827070/seven-hills',
              },
              {
                id: 'ugljan-header-neighborhoods-item-settlers-village',
                content: 'settlers village',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827069/settlers-village',
              },
              {
                id: 'ugljan-header-neighborhoods-item-settlers-crossing',
                content: "settler's crossing",
                url: "https://www.colohomesforsale.com/neighborhoods/153827068/settler's-crossing",
              },
              {
                id: 'ugljan-header-neighborhoods-item-serenity-ridge',
                content: 'serenity ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827067/serenity-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-item-second-creek-village',
                content: 'second creek village',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827066/second-creek-village',
              },
              {
                id: 'ugljan-header-neighborhoods-item-second-creek-farms',
                content: 'second creek farms',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827065/second-creek-farms',
              },
              {
                id: 'ugljan-header-neighborhoods-item-second-appletree-east',
                content: 'second appletree east',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827064/second-appletree-east',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sandpiper',
                content: 'sandpiper',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827063/sandpiper',
              },
              {
                id: 'ugljan-header-neighborhoods-item-san-francisco',
                content: 'san francisco',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827062/san-francisco',
              },
              {
                id: 'ugljan-header-neighborhoods-item-saddle-rock',
                content: 'saddle rock',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827061/saddle-rock',
              },
              {
                id: 'ugljan-header-neighborhoods-item-saddle-rock-ridge',
                content: 'saddle rock ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827060/saddle-rock-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-item-saddle-rock-ranches',
                content: 'saddle rock ranches',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827059/saddle-rock-ranches',
              },
              {
                id: 'ugljan-header-neighborhoods-item-saddle-rock-north',
                content: 'saddle rock north',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827058/saddle-rock-north',
              },
              {
                id: 'ugljan-header-neighborhoods-item-saddle-rock-highlands',
                content: 'saddle rock highlands',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827057/saddle-rock-highlands',
              },
              {
                id: 'ugljan-header-neighborhoods-item-saddle-rock-golf-homes',
                content: 'saddle rock golf homes',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827056/saddle-rock-golf-homes',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sable-village',
                content: 'sable village',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827055/sable-village',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sable-ridge',
                content: 'sable ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827054/sable-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sable-cove',
                content: 'Sable cove',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827053/sable-cove',
              },
              {
                id: 'ugljan-header-neighborhoods-item-sable-altura-chambers',
                content: 'Sable altura chambers',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827052/sable-altura-chambers',
              },
              {
                id: 'ugljan-header-neighborhoods-item-rose',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127784/Rose-Hill',
                content: 'ROSE HILL',
              },
              {
                id: 'ugljan-header-neighborhoods-item-rose-gardens',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132433/Rose-Gardens',
                content: 'ROSE GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-rocky',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131596/Rocky-Ridge',
                content: 'ROCKY RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-rocky-mountain',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132550/Rocky-Mountain-Arsenal-National-Wildlife-Refuge',
                content: 'ROCKY MOUNTAIN ARSENAL NATIONAL WILDLIFE REFUGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-horse',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132039/Rocking-Horse',
                content: 'ROCKING HORSE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-robinwood',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127552/Robinwood-Condominiums',
                content: 'ROBINWOOD CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-ridge',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127445/Ridgeview-Glen',
                content: 'RIDGEVIEW GLEN',
              },
              {
                id: 'ugljan-header-neighborhoods-item-ridgeview',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132458/Ridgeview-Eagle-Bend',
                content: 'RIDGEVIEW EAGLE BEND',
              },
              {
                id: 'ugljan-header-neighborhoods-item-reunion',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131511/Reunion',
                content: 'REUNION',
              },
              {
                id: 'ugljan-header-neighborhoods-item-redbuck',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131686/Redbuck',
                content: 'REDBUCK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-red-sky',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130342/Red-Sky-Condominiums',
                content: 'RED SKY CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-rattlesnake',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128945/Rattlesnake-Ridge',
                content: 'RATTLESNAKE RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-range-view',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129261/Range-View',
                content: 'RANGE VIEW',
              },
              {
                id: 'ugljan-header-neighborhoods-item-rancho',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131841/Rancho-Manor',
                content: 'RANCHO MANOR',
              },
              {
                id: 'ugljan-header-neighborhoods-item-rainbow-gardens',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132087/Rainbow-Gardens',
                content: 'RAINBOW GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-quincy',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129442/Quincy-Ridge',
                content: 'QUINCY RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-quincy-lake',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131470/Quincy-Lake',
                content: 'QUINCY LAKE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-quincy-hill',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132358/Quincy-Hill',
                content: 'QUINCY HILL',
              },
              {
                id: 'ugljan-header-neighborhoods-item-quincy-creek',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132144/Quincy-Creek',
                content: 'QUINCY CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-queensborough',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128325/Queensborough',
                content: 'QUEENSBOROUGH',
              },
              {
                id: 'ugljan-header-neighborhoods-item-quail-run-condominiums',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129326/Quail-Run-Condominiums',
                content: 'QUAIL RUN CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Ptarmigan-Park',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128523/Ptarmigan-Park',
                content: 'PTARMIGAN PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Pride-Crossing',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129988/Pride-Crossing',
                content: 'PRIDE CROSSING',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Prairie-Ridge-at-Saddle-Rock-East',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129427/Prairie-Ridge-at-Saddle-Rock-East',
                content: 'PRAIRIE RIDGE AT SADDLE ROCK EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Prairie-Farm',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131147/Prairie-Farms',
                content: 'PRAIRIE FARMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Prairie-Creek',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129118/Prairie-Creek',
                content: 'PRAIRIE CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Potomac-Farms',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130683/Potomac-Farms',
                content: 'POTOMAC FARMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Pontiac-Gardens',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131499/Pontiac-Gardens',
                content: 'PONTIAC GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Pontiac-Estates',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127785/Pontiac-Estates',
                content: 'PONTIAC ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Ponderosa',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127000/Ponderosa',
                content: 'PONDEROSA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Plains-Conservation-Center',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131892/Plains-Conservation-Center',
                content: 'PLAINS CONSERVATION CENTER',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Pioneer-Hills',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131891/Pioneer-Hills',
                content: 'PIONEER HILLS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Pinnacle-at-the-Creek',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129285/Pinnacle-at-the-Creek',
                content: 'PINNACLE AT THE CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Piney-Creek',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127530/Piney-Creek',
                content: 'PINEY CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Piney-Creek-Village',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131510/Piney-Creek-Village',
                content: 'PINEY CREEK VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-piney-Creek-Ranches',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128972/Piney-Creek-Ranches',
                content: 'PINEY CREEK RANCHES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Pierpoint-VI',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131566/Pierpoint-VI',
                content: 'PIERPOINT VI',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Pierpoint-7',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128262/Pier-Point-7',
                content: 'PIER POINT 7',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Pheasant-Run',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130451/Pheasant-Run',
                content: 'PHEASANT RUN',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Pheasant-Run-Townhomes',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128601/Pheasant-Run-Townhomes',
                content: 'PHEASANT RUN TOWNHOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Peterson',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129100/Peterson',
                content: 'PETERSON',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Peoria-Villa',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132247/Peoria-Villas',
                content: 'PEORIA VILLAS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Peoria-Park',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128904/Peoria-Park',
                content: 'PEORIA PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Peninsula-Condominiums',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129080/Peninsula-Condominiums',
                content: 'PENINSULA CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Peachwood',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128356/Peachwood',
                content: 'PEACHWOOD',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Parkview',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127516/Parkview',
                content: 'PARKVIEW',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Parkview-Heights',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128094/Parkview-Heights',
                content: 'PARKVIEW HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Parkside-at-Reunion',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129320/Parkside-at-Reunion',
                content: 'PARKSIDE AT REUNION',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Parkborough',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129288/Parkborough',
                content: 'PARKBOROUGH',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Park-Way-Condominiums',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130059/Park-Way-Condominiums',
                content: 'PARK WAY CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Park-Villas',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129336/Park-Villas',
                content: 'PARK VILLAS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Park-View',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128454/Park-View',
                content: 'PARK VIEW',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Park-View-Terrace',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131503/Park-View-Terrace',
                content: 'PARK VIEW TERRACE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Park-View-ridge',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129757/Park-View-Ridge',
                content: 'PARK VIEW RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Park-View-meadows',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128305/Park-View-Meadows',
                content: 'PARK VIEW MEADOWS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Park-View-estate',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128641/Park-View-Estates',
                content: 'PARK VIEW ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Park-Place-Villas',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129721/Park-Place-Villas',
                content: 'PARK PLACE VILLAS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Park-East',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128771/Park-East',
                content: 'PARK EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Park-Avenue',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131960/Park-Avenue',
                content: 'PARK AVENUE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Orchard-Valley',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131582/Orchard-Valley',
                content: 'ORCHARD VALLEY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Oliveglen-Village',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130055/Oliveglen-Village',
                content: 'OLIVEGLEN VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Olde-Towne',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127180/Olde-Towne',
                content: 'OLDE TOWNE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Oakland-Court',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/126994/Oakland-Court',
                content: 'OAKLAND COURT',
              },
              {
                id: 'ugljan-header-neighborhoods-item-norwood-park',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130165/Norwood-Park',
                content: 'NORWOOD PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-northwest-aurora',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127707/Northwest-Aurora',
                content: 'NORTHWEST AURORA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-northeast',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129833/Northeast',
                content: 'NORTHEAST',
              },
              {
                id: 'ugljan-header-neighborhoods-item-North-Range-Village',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131570/North-Range-Village',
                content: 'NORTH RANGE VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-North-Range-town',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129172/North-Range-Town-Center',
                content: 'NORTH RANGE TOWN CENTER',
              },
              {
                id: 'ugljan-header-neighborhoods-item-nortfolk-one',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131415/Norfolk-Glen',
                content: 'NORFOLK GLEN',
              },
              {
                id: 'ugljan-header-neighborhoods-item-nortfolk-two',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129148/Norfolk-Glen',
                content: 'NORFOLK GLEN',
              },
              {
                id: 'ugljan-header-neighborhoods-item-1',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/126872/New-World-West',
                content: 'NEW WORLD WEST',
              },
              {
                id: 'ugljan-header-neighborhoods-item-2',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130421/New-England-Heights',
                content: 'NEW ENGLAND HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-3',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127948/Nevin-Village-Green',
                content: 'NEVIN VILLAGE GREEN',
              },
              {
                id: 'ugljan-header-neighborhoods-item-4',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128072/Nantucket',
                content: 'NANTUCKET',
              },
              {
                id: 'ugljan-header-neighborhoods-item-5',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127904/Murphy-Creek',
                content: 'MURPHY CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-6',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128394/Mowrey',
                content: 'MOWREY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-7',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131638/Mountainview-Estates',
                content: 'MOUNTAINVIEW ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-8',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130325/Morris-Heights',
                content: 'MORRIS HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-9',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131196/Moon-Shadow-at-Saddle-Rock',
                content: 'MOON SHADOW AT SADDLE ROCK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-10',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131478/Monaco',
                content: 'MONACO',
              },
              {
                id: 'ugljan-header-neighborhoods-item-11',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132135/Monaco-Vista',
                content: 'MONACO VISTA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-12',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130142/Mission-Viejo',
                content: 'MISSION VIEJO',
              },
              {
                id: 'ugljan-header-neighborhoods-item-13',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129480/Mill-Valley',
                content: 'MILL VALLEY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-14',
                content: 'MILL RUN',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132541/Mill-Run',
              },
              {
                id: 'ugljan-header-neighborhoods-item-15',
                content: 'MESA',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129498/Mesa',
              },
              {
                id: 'ugljan-header-neighborhoods-item-16',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129421/Memory-Lane-Gardens',
                content: 'MEMORY LANE GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-17',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128984/Meadow-Wood',
                content: 'MEADOW WOOD',
              },
              {
                id: 'ugljan-header-neighborhoods-item-18',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127803/Meadow-Point-Condominiums',
                content: 'MEADOW POINT CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-19',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130199/Meadow-Hills',
                content: 'MEADOW HILLS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-20',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129614/Manchester-Park',
                content: 'MANCHESTER PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-21',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128026/Mabry',
                content: 'MABRY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-22',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129016/Lynwood-Heights',
                content: 'LYNWOOD HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-23',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130944/Lynn-Knoll',
                content: 'LYNN KNOLL',
              },
              {
                id: 'ugljan-header-neighborhoods-item-24',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131442/Lynknoll',
                content: 'LYNKNOLL',
              },
              {
                id: 'ugljan-header-neighborhoods-item-25',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131076/Lyn-Meadows',
                content: 'LYN MEADOWS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-26',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130600/Lyn-Acres',
                content: 'LYN ACRES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-27',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127666/Lydeva-Heights',
                content: 'LYDEVA HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-28',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132107/Louisiana-Purchase-II',
                content: 'LOUISIANA PURCHASE II',
              },
              {
                id: 'ugljan-header-neighborhoods-item-29',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127935/Linden',
                content: 'LINDEN',
              },
              {
                id: 'ugljan-header-neighborhoods-item-',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132449/Lexington',
                content: 'LEXINGTON',
              },
              {
                id: 'ugljan-header-neighborhoods-item-30',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127752/Lexington-East',
                content: 'LEXINGTON EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-item-31',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131492/Leisure-Landing',
                content: 'LEISURE LANDING',
              },
              {
                id: 'ugljan-header-neighborhoods-item-32',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132069/Legacy-Park',
                content: 'LEGACY PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-33',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131077/Le-Chateau',
                content: 'LE CHATEAU',
              },
              {
                id: 'ugljan-header-neighborhoods-item-34',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127332/Lazy-Hills-Ranchettes',
                content: 'LAZY HILLS RANCHETTES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-35',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132540/Laredo-Highline',
                content: 'LAREDO HIGHLINE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-36',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130352/Lakeview-Terrace',
                content: 'LAKEVIEW TERRACE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-37',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127748/Lakeshore',
                content: 'LAKESHORE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-38',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130641/Lake-Pointe',
                content: 'LAKE POINTE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-39',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127778/Lake-Estates',
                content: 'LAKE ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-40',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129590/La-Vista',
                content: 'LA VISTA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-41',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129507/Kragelund-Acres',
                content: 'KRAGELUND ACRES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-42',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127885/Kirkegaard-Acres',
                content: 'KIRKEGAARD ACRES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-43',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127378/Kirkegaard-Acres',
                content: 'KIRKEGAARD ACRES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-44',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127565/Kingsborough',
                content: 'KINGSBOROUGH',
              },
              {
                id: 'ugljan-header-neighborhoods-item-45',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127627/Kingsborough',
                content: 'KINGSBOROUGH',
              },
              {
                id: 'ugljan-header-neighborhoods-item-46',
                url: 'https://www.colohomesforsale.com/neighborhoods/153826935/kennedy',
                content: 'KENNEDY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-47',
                url: 'https://www.colohomesforsale.com/neighborhoods/153826934/kennedy',
                content: 'KENNEDY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-48',
                content: 'KEMP',
                url: 'https://www.colohomesforsale.com/neighborhoods/153826933/kemp',
              },
              {
                id: 'ugljan-header-neighborhoods-item-49',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132110/Kearney-Heights',
                content: 'KEARNEY HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-50',
                content: 'KAREN PARK',
                url: 'https://www.colohomesforsale.com/neighborhoods/153826931/karen-park',
              },
              {
                id: 'ugljan-header-neighborhoods-item-51',
                content: 'JUDD',
                url: 'https://www.colohomesforsale.com/neighborhoods/153826930/judd',
              },
              {
                id: 'ugljan-header-neighborhoods-item-52',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128035/Jamaica-Park',
                content: 'JAMAICA PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-53',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130961/Jackson-Farm',
                content: 'JACKSON FARM',
              },
              {
                id: 'ugljan-header-neighborhoods-item-54',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131273/Ivy-Hill',
                content: 'IVY HILL',
              },
              {
                id: 'ugljan-header-neighborhoods-item-55',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130918/Irondale',
                content: 'IRONDALE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-56',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132382/Irondale-Gardens',
                content: 'IRONDALE GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-57',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129281/Innsbruck',
                content: 'INNSBRUCK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-58',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132223/Imperial-Park',
                content: 'IMPERIAL PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-59',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131104/Iliff-Homes',
                content: 'ILIFF HOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-60',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131253/Iliff-Commons',
                content: 'ILIFF COMMONS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-61',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132259/Horseshoe-Park',
                content: 'HORSESHOE PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-62',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132074/Horizons',
                content: 'HORIZONS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-63',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128411/Holly-Heights',
                content: 'HOLLY HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-64',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131075/Hoffman-Heights-/-Jewell-Heights',
                content: 'HOFFMAN HEIGHTS / JEWELL HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-65',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127301/Hillside',
                content: 'HILLSIDE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-66',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128439/Hillcrest-Villages',
                content: 'HILLCREST VILLAGES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-67',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127761/Hill-Top',
                content: 'HILL TOP',
              },
              {
                id: 'ugljan-header-neighborhoods-item-68',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129863/Highpoint',
                content: 'HIGHPOINT',
              },
              {
                id: 'ugljan-header-neighborhoods-item-69',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132386/Highline-Villages',
                content: 'HIGHLINE VILLAGES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-70',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130313/Highline-Court',
                content: 'HIGHLINE COURT',
              },
              {
                id: 'ugljan-header-neighborhoods-item-71',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129583/Highline-/-Expo',
                content: 'HIGHLINE / EXPO',
              },
              {
                id: 'ugljan-header-neighborhoods-item-72',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131535/Highlands-at-Buffalo-Run-East',
                content: 'HIGHLANDS AT BUFFALO RUN EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-item-73',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127361/Highland-Park',
                content: 'HIGHLAND PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-74',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131361/High-Pointe',
                content: 'HIGH POINTE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-75',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127546/High-Hollows-Condominiums',
                content: 'HIGH HOLLOWS CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-76',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129998/Hidden-Valley',
                content: 'HIDDEN VALLEY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-77',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128521/Heritage-Eagle-Bend',
                content: 'HERITAGE EAGLE BEND',
              },
              {
                id: 'ugljan-header-neighborhoods-item-78',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131865/Heather-Ridge',
                content: 'HEATHER RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-79',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129792/Heather-Ridge-South',
                content: 'HEATHER RIDGE SOUTH',
              },

              {
                id: 'ugljan-header-neighborhoods-item-80',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127892/Heather-Gardens',
                content: 'HEATHER GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-81',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132139/Hearthstone',
                content: 'HEARTHSTONE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-82',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130079/Hawthorn',
                content: 'HAWTHORN',
              },
              {
                id: 'ugljan-header-neighborhoods-item-83',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132567/Havana',
                content: 'HAVANA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-84',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130040/Havana-Villa',
                content: 'HAVANA VILLA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-85',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128663/Havana-Park',
                content: 'HAVANA PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-86',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128115/Havana-Heights',
                content: 'HAVANA HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-87',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127600/Harvest-Meadows',
                content: 'HARVEST MEADOWS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-88',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130376/Hampden-Villas',
                content: 'HAMPDEN VILLAS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-89',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127417/Hampden-Towncenter',
                content: 'HAMPDEN TOWNCENTER',
              },
              {
                id: 'ugljan-header-neighborhoods-item-90',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128228/Hampden-Hills',
                content: 'HAMPDEN HILLS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-91',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128616/Hallcrafts-Village-East',
                content: 'HALLCRAFTS VILLAGE EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-item-92',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127857/Gun-Club-Estates',
                content: 'GUN CLUB ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-93',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129413/Grout',
                content: 'GROUT',
              },
              {
                id: 'ugljan-header-neighborhoods-item-94',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127670/Greenfield',
                content: 'GREENFIELD',
              },
              {
                id: 'ugljan-header-neighborhoods-item-95',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128438/Greenfield-Masters',
                content: 'GREENFIELD MASTERS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-96',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128991/Greenbrook',
                content: 'GREENBROOK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-97',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127030/Granby-Commons',
                content: 'GRANBY COMMONS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-98',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128172/Grahams',
                content: 'GRAHAMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-99',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130467/Gilligan',
                content: 'GILLIGAN',
              },
              {
                id: 'ugljan-header-neighborhoods-item-100',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129691/Gifford-Gardens',
                content: 'GIFFORD GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-101',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129503/GentryCountry-Homes',
                content: 'GENTRYCOUNTRY HOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-102',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127773/Gateway-Townhomes',
                content: 'GATEWAY TOWNHOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-103',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129710/Gateway-Park',
                content: 'GATEWAY PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-104',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132015/Garden-Heights',
                content: 'GARDEN HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-105',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129840/Gamble',
                content: 'GAMBLE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-106',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129547/Gamble-Derby',
                content: 'GAMBLE DERBY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-107',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129130/Fronterra-Village',
                content: 'FRONTERRA VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-108',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127961/Fronterra-Village-Townhomes',
                content: 'FRONTERRA VILLAGE TOWNHOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-109',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128697/Friendly-Village',
                content: 'FRIENDLY VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-110',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129563/French-Creek',
                content: 'FRENCH CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-111',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127625/Foxton-Village',
                content: 'FOXTON VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-112',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131388/Foxfield',
                content: 'FOXFIELD',
              },
              {
                id: 'ugljan-header-neighborhoods-item-113',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129579/Foxdale-Condominiums',
                content: 'FOXDALE CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-114',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131365/Fox-Hill',
                content: 'FOX HILL',
              },
              {
                id: 'ugljan-header-neighborhoods-item-115',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128293/Fountain-Side',
                content: 'FOUNTAIN SIDE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-116',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128248/Fitzsimons',
                content: 'FITZSIMONS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-117',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127168/First-Creek-Farm',
                content: 'FIRST CREEK FARM',
              },
              {
                id: 'ugljan-header-neighborhoods-item-118',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132201/Finkbeiner',
                content: 'FINKBEINER',
              },
              {
                id: 'ugljan-header-neighborhoods-item-119',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128823/Fernald-/-Frei-Crossing',
                content: 'FERNALD / FREI CROSSING',
              },
              {
                id: 'ugljan-header-neighborhoods-item-120',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132116/Fairway',
                content: 'FAIRWAY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-121',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129796/Fairway-Village',
                content: 'FAIRWAY VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-122',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128129/Fairfield-Village',
                content: 'FAIRFIELD VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-123',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128482/Fairfax-Park',
                content: 'FAIRFAX PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-124',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127076/Eureka',
                content: 'EUREKA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-125',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128890/Estancia',
                content: 'ESTANCIA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-126',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127742/Embarcadero-in-Willowridge',
                content: 'EMBARCADERO IN WILLOWRIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-127',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132046/Elk-Ridge',
                content: 'ELK RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-128',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128095/Eastwood-Townhomes',
                content: 'EASTWOOD TOWNHOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-129',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131432/East-Ridge-/-Ptarmigan-Park',
                content: 'EAST RIDGE / PTARMIGAN PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-130',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128979/East-Quincy-Highlands',
                content: 'EAST QUINCY HIGHLANDS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-131',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128694/East-Colfax',
                content: 'EAST COLFAX',
              },
              {
                id: 'ugljan-header-neighborhoods-item-132',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/126982/Eagle-Village',
                content: 'EAGLE VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-133',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128800/Eagle-Creek',
                content: 'EAGLE CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-134',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127968/Dupont',
                content: 'DUPONT',
              },
              {
                id: 'ugljan-header-neighborhoods-item-135',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/126907/Dover',
                content: 'DOVER',
              },
              {
                id: 'ugljan-header-neighborhoods-item-136',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130191/Dove-Hill',
                content: 'DOVE HILL',
              },
              {
                id: 'ugljan-header-neighborhoods-item-137',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129143/Derby',
                content: 'DERBY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-138',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131787/Denver-International-Tech-Center',
                content: 'DENVER INTERNATIONAL TECH CENTER',
              },
              {
                id: 'ugljan-header-neighborhoods-item-139',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129581/Denver-International-Airport',
                content: 'DENVER INTERNATIONAL AIRPORT',
              },
              {
                id: 'ugljan-header-neighborhoods-item-140',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131171/Del-Mar',
                content: 'DEL MAR',
              },
              {
                id: 'ugljan-header-neighborhoods-item-141',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131949/Del-Mar-Parkway',
                content: 'DEL MAR PARKWAY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-142',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131651/Del-Mar-Park-Condominiums',
                content: 'DEL MAR PARK CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-143',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128919/Deer-Pointe-Village-Condominiums',
                content: 'DEER POINTE VILLAGE CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-144',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128166/Daybreak',
                content: 'DAYBREAK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-145',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128854/Dam-East-/-West',
                content: 'DAM EAST / WEST',
              },
              {
                id: 'ugljan-header-neighborhoods-item-146',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129065/Cutler',
                content: 'CUTLER',
              },
              {
                id: 'ugljan-header-neighborhoods-item-147',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128168/Crown-Point',
                content: 'CROWN POINT',
              },
              {
                id: 'ugljan-header-neighborhoods-item-148',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128867/Cross-Creek',
                content: 'CROSS CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-149',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131645/Crestridge',
                content: 'CRESTRIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-150',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129500/Creekview',
                content: 'CREEKVIEW',
              },
              {
                id: 'ugljan-header-neighborhoods-item-151',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128675/Creekside-Eagle-Bend',
                content: 'CREEKSIDE EAGLE BEND',
              },
              {
                id: 'ugljan-header-neighborhoods-item-152',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128323/Coyote-Ranch',
                content: 'COYOTE RANCH',
              },
              {
                id: 'ugljan-header-neighborhoods-item-153',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127782/Country-Village',
                content: 'COUNTRY VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-154',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131661/Country-Lane',
                content: 'COUNTRY LANE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-155',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132200/Country-Green',
                content: 'COUNTRY GREEN',
              },
              {
                id: 'ugljan-header-neighborhoods-item-156',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132411/Country-Club-Village',
                content: 'COUNTRY CLUB VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-157',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131589/Cottonwood',
                content: 'COTTONWOOD',
              },
              {
                id: 'ugljan-header-neighborhoods-item-158',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129598/Copperleaf',
                content: 'COPPERLEAF',
              },
              {
                id: 'ugljan-header-neighborhoods-item-159',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129094/Copper-Ridge',
                content: 'COPPER RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-160',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129523/Conter-Estates',
                content: 'CONTER ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-161',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128126/Conservatory',
                content: 'CONSERVATORY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-162',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127486/Columbine-Ranches',
                content: 'COLUMBINE RANCHES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-163',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129502/Colfax-Villa',
                content: 'COLFAX VILLA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-164',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129023/Cobblewood',
                content: 'COBBLEWOOD',
              },
              {
                id: 'ugljan-header-neighborhoods-item-165',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129677/Clermont',
                content: 'CLERMONT',
              },
              {
                id: 'ugljan-header-neighborhoods-item-166',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128915/Churchill',
                content: 'CHURCHILL',
              },
              {
                id: 'ugljan-header-neighborhoods-item-167',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131124/Cherry',
                content: 'CHERRY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-168',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132543/Cherry-Grove-East',
                content: 'CHERRY GROVE EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-item-169',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131366/Cherry-Creek-Rancho',
                content: 'CHERRY CREEK RANCHO',
              },
              {
                id: 'ugljan-header-neighborhoods-item-170',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127097/Cherry-Creek-Racquet-Club',
                content: 'CHERRY CREEK RACQUET CLUB',
              },
              {
                id: 'ugljan-header-neighborhoods-item-171',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127080/Cherry-Creek-East',
                content: 'CHERRY CREEK EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-item-172',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130801/Cherry-Brook',
                content: 'CHERRY BROOK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-173',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131255/Cherokee',
                content: 'CHEROKEE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-174',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127470/Chenango',
                content: 'CHENANGO',
              },
              {
                id: 'ugljan-header-neighborhoods-item-175',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128140/Chelsea',
                content: 'CHELSEA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-176',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132131/Chelsea-Park',
                content: 'CHELSEA PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-177',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129240/Charleston-Place-Condominiums',
                content: 'CHARLESTON PLACE CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-178',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130700/Chapparal',
                content: 'CHAPPARAL',
              },
              {
                id: 'ugljan-header-neighborhoods-item-179',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127769/Chapman',
                content: 'CHAPMAN',
              },
              {
                id: 'ugljan-header-neighborhoods-item-180',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129560/Champagne',
                content: 'CHAMPAGNE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-181',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129587/Chambers-Ridge',
                content: 'CHAMBERS RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-182',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128235/Chambers-Heights',
                content: 'CHAMBERS HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-183',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128066/Chambers-Estates',
                content: 'CHAMBERS ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-184',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131935/Chaddsford',
                content: 'CHADDSFORD',
              },
              {
                id: 'ugljan-header-neighborhoods-item-185',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128628/Century-City-Condominiums',
                content: 'CENTURY CITY CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-186',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130356/Centretech',
                content: 'CENTRETECH',
              },
              {
                id: 'ugljan-header-neighborhoods-item-187',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127528/Central',
                content: 'CENTRAL',
              },
              {
                id: 'ugljan-header-neighborhoods-item-188',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132094/Central-Park-Townhomes',
                content: 'CENTRAL PARK TOWNHOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-189',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127014/Center-Pointe',
                content: 'CENTER POINTE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-190',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129532/Cedar-Cove',
                content: 'CEDAR COVE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-191',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132404/Carriage-Village',
                content: 'CARRIAGE VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-192',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128862/Carriage-Place',
                content: 'CARRIAGE PLACE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-193',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128501/Carnation-Meadows',
                content: 'CARNATION MEADOWS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-194',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129604/Burns-Aurora',
                content: 'BURNS AURORA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-195',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128494/Buffalo-Mesa',
                content: 'BUFFALO MESA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-196',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132409/Buffalo-Highlands',
                content: 'BUFFALO HIGHLANDS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-197',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127273/Buckley-Ranch',
                content: 'BUCKLEY RANCH',
              },
              {
                id: 'ugljan-header-neighborhoods-item-198',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131201/Buckley-Air-Force-Base',
                content: 'BUCKLEY AIR FORCE BASE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-199',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/126916/Buckingham-Oaks-Condominiums',
                content: 'BUCKINGHAM OAKS CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-200',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128565/Brookvale',
                content: 'BROOKVALE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-201',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131907/Brookhaven-Condominiums',
                content: 'BROOKHAVEN CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-202',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130071/Broadstone-Cornerstone',
                content: 'BROADSTONE CORNERSTONE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-203',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128056/Brittany-Highlands',
                content: 'BRITTANY HIGHLANDS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-204',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127123/Briarwood',
                content: 'BRIARWOOD',
              },
              {
                id: 'ugljan-header-neighborhoods-item-205',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129189/Breakaway-Condominiums',
                content: 'BREAKAWAY CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-206',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129457/Brandychase',
                content: 'BRANDYCHASE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-207',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129635/Brandychase-Condominiums',
                content: 'BRANDYCHASE CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-208',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132256/Brandon-Park-Condominiums',
                content: 'BRANDON PARK CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-209',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128186/Bradell-Estates',
                content: 'BRADELL ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-210',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131488/Bowen',
                content: 'BOWEN',
              },
              {
                id: 'ugljan-header-neighborhoods-item-211',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127890/Bogner',
                content: 'BOGNER',
              },
              {
                id: 'ugljan-header-neighborhoods-item-212',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132600/Blackstone',
                content: 'BLACKSTONE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-213',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129888/Black-Hawk-Derby',
                content: 'BLACK HAWK DERBY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-214',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127416/Beverly-Homes',
                content: 'BEVERLY HOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-215',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128337/Bestview-Acres',
                content: 'BESTVIEW ACRES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-216',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128648/Berkshire',
                content: 'BERKSHIRE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-217',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127428/Berkshire-Place',
                content: 'BERKSHIRE PLACE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-218',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132138/Bel-Aire-Estates',
                content: 'BEL-AIRE ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-219',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129568/Beacon-Point',
                content: 'BEACON POINT',
              },
              {
                id: 'ugljan-header-neighborhoods-item-220',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130753/Bayberry-Condominiums',
                content: 'BAYBERRY CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-221',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127753/Barr-Lake-Estates',
                content: 'BARR LAKE ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-222',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129215/Banyon-Hollow-Condominiums',
                content: 'BANYON HOLLOW CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-223',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131528/Avalon',
                content: 'AVALON',
              },
              {
                id: 'ugljan-header-neighborhoods-item-224',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130908/Aurora-Vista',
                content: 'AURORA VISTA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-225',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132129/Aurora-Vista',
                content: 'AURORA VISTA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-226',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130104/Aurora-Meadows',
                content: 'AURORA MEADOWS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-227',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127058/Aurora-Knolls',
                content: 'AURORA KNOLLS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aurora-Knolls-South',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127466/Aurora-Knolls-South',
                content: 'AURORA KNOLLS SOUTH',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Hutchinson-Heights',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131050/Aurora-Knolls-/-Hutchinson-Heights',
                content: 'AURORA KNOLLS / HUTCHINSON HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aurora-Hills',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130577/Aurora-Hills',
                content: 'AURORA HILLS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aurora-Hills-Golf-Course',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127443/Aurora-Hills-Golf-Course',
                content: 'AURORA HILLS GOLF COURSE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aurora-Highlands',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127373/Aurora-Highlands',
                content: 'AURORA HIGHLANDS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aurora-Highlands-2',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132215/Aurora-Highlands',
                content: 'AURORA HIGHLANDS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aurora-Heights',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130152/Aurora-Heights',
                content: 'AURORA HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aurora-East-Village',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130359/Aurora-East-Village',
                content: 'AURORA EAST VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aurora-East-Planned-Community',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127931/Aurora-East-Planned-Community',
                content: 'AURORA EAST PLANNED COMMUNITY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aurora-City-Center',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128781/Aurora-City-Center',
                content: 'AURORA CITY CENTER',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aurora-City-Center-North',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128443/Aurora-City-Center-North',
                content: 'AURORA CITY CENTER NORTH',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aurora-Cascades',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131233/Aurora-Cascades',
                content: 'AURORA CASCADES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Auburn-Hill',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127943/Auburn-Hill',
                content: 'AUBURN HILL',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aspen-ridge',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128321/Aspen-Ridge',
                content: 'ASPEN RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aspen-hills',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127791/Aspen-Hills',
                content: 'ASPEN HILLS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aspen-Creek',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/126893/Aspen-Creek',
                content: 'ASPEN CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Asbury-Townhomes',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130110/Asbury-Townhomes',
                content: 'ASBURY TOWNHOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Arapahoe-Meadows',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127671/Arapahoe-Meadows',
                content: 'ARAPAHOE MEADOWS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Arapahoe-Heights',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128271/Arapahoe-Heights',
                content: 'ARAPAHOE HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Appletree-East',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131560/Appletree-East',
                content: 'APPLETREE EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Apache-Mesa',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130713/Apache-Mesa',
                content: 'APACHE MESA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Apache-Meadows',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128878/Apache-Meadows',
                content: 'APACHE MEADOWS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Antelope',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129831/Antelope',
                content: 'ANTELOPE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Andover-Glen-at-Chery-Creek',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131402/Andover-Glen-at-Chery-Creek',
                content: 'ANDOVER GLEN AT CHERY CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Anderson',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127780/Anderson',
                content: 'ANDERSON',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Americana',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130160/Americana',
                content: 'AMERICANA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Alvarado-Place',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131649/Alvarado-Place',
                content: 'ALVARADO PLACE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Allied',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131484/Allred',
                content: 'ALLRED',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Allied-Waste',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132090/Allied-Waste',
                content: 'ALLIED WASTE',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Albeck',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127399/Albeck',
                content: 'ALBECK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Advance-Mobile-Park',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128170/Advance-Mobile-Park',
                content: 'ADVANCE MOBILE PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Adonea',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132096/Adonea',
                content: 'ADONEA',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Adams-Heights',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130867/Adams-Heights',
                content: 'ADAMS HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Adams-City',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128589/Adams-City',
                content: 'ADAMS CITY',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Adams-City-Poultry-Farms',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131794/Adams-City-Poultry-Farms',
                content: 'ADAMS CITY POULTRY FARMS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Adams-City-Gardens',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130000/Adams-City-Gardens',
                content: 'ADAMS CITY GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-item-Aamodt',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129773/Aamodt',
                content: 'AAMODT',
              },
              {
                id: 'ugljan-header-neighborhoods-item-56th-Avenue-Industrial-Park',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128277/56th-Avenue-Industrial-Park',
                content: '56TH AVENUE INDUSTRIAL PARK',
              },
            ],
          },
          {
            id: 'ugljan-sold-listings-item',
            content: 'SOLD LISTINGS',
            url: 'https://www.colohomesforsale.com/sold-listing',
          },
          {
            id: 'ugljan-exclusive-trade-item',
            url: 'https://trade-in.colohomesforsale.com/',
            content: 'EXCLUSIVE TRADE-IN PROGRAM',
          },
          {
            id: 'ugljan-contact-item',
            content: 'CONTACT',
            url: 'https://www.colohomesforsale.com/contact',
          },
          {
            id: 'ugljan-cash-offers-item',
            url: 'https://cash-offer-ugljan.netlify.app/',
            content: 'CASH OFFERS',
          },
          {
            id: 'ugljan-review-item',
            content: 'REVIEW',
            url: 'https://www.colohomesforsale.com/reviews',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: 'on-ugljan-mheader-logo-container',
          imageClassName: 'on-ugljan-mheader-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'on-ugljan-hamburger',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-ugljan-mheader',
        headerSectionsClassName: 'on-ugljan-mheader-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-ugljan-mheader-menu',
        itemClassName: 'on-ugljan-mheader-item',
        menuClassName: 'on-ugljan-mheader-folder',
        menuLabelActiveClassName: 'on-ugljan-mheader-label-active',
        menuItemClassName: 'on-ugljan-mheader-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'ugljan-mobile-listings-item',
            content: 'LISTINGS',
            menuItems: [
              {
                id: 'ugljan-mobile-listings-item-one',
                content: 'All Listings',
                url: 'https://www.colohomesforsale.com/listing',
              },
              {
                id: 'ugljan-mobile-listings-item-two',
                content: 'Featured Listings',
                url: 'https://www.colohomesforsale.com/featured-listing',
              },
            ],
          },
          {
            id: 'ugljan-mobile-selling-item',
            content: 'SELLING',
            menuItems: [
              {
                id: 'ugljan-mobile-selling-item-one',
                content: 'Sell My Home',
                url: 'https://www.colohomesforsale.com/sell',
              },
              {
                id: 'ugljan-mobile-selling-item-two',
                content: 'Home Evaluation',
                url: 'https://www.colohomesforsale.com/evaluation',
              },
            ],
          },
          {
            id: 'ugljan-mobile-about-item',
            content: 'ABOUT',
            url: 'https://www.colohomesforsale.com/about',
          },
          {
            id: 'ugljan-mobile-neighborhoods-item',
            content: 'NEIGHBORHOODS',
            menuItems: [
              {
                id: 'ugljan-header-neighborhoods-menu-item-zurchers',
                content: 'ZURCHERS',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130541/Zurchers',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-yacovetta',
                content: 'YACOVETTA',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128429/Yacovetta',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-woodrim-crossing',
                url: 'www.colohomesforsale.com/neighborhood-detail/127093/Woodrim-Crossing',
                content: 'WOODRIM CROSSING',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-woodgate',
                content: 'WOODGATE',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128484/Woodgate',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-windsor',
                content: 'WINDSOR AT MEADOW HILLS',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130810/Windsor-at-Meadow-Hills"',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-windsor-park',
                content: 'WINDSOR PARK',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131254/Windsor-Park',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-windmill',
                content: 'WINDMILL CREEK RESERVE',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129611/Windmill-Creek-Reserve',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-',
                content: 'WINDCREEK CONDOMINIUMS',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130978/Windcreek-Condominiums',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-winchester',
                content: 'WINCHESTER',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128291/Winchester',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-willowridge',
                content: 'WILLOWRIDGE TOWNHOMES',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130058/Willowridge-Townhomes',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-willow-trace',
                content: 'WILLOW TRACE',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130794/Willow-Trace',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-willow-park',
                content: 'WILLOW PARK',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130472/Willow-Park',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-wildwood',
                content: 'WILDWOOD',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129027/Wildwood',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-wilco',
                content: 'WILCO',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127978/Wilco',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-wikiup',
                content: 'WIKIUP',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131232/Wikiup',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-wheatlands',
                content: 'WHEATLANDS',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131495/Wheatlands',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-waters-edge',
                content: 'WATERS EDGE',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127251/Waters-Edge',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-waterfield',
                content: 'WATERFIELD COURT',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131549/Waterfield-Court',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-virginia-heights',
                content: 'VIRGINIA HEIGHTS',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131763/Virginia-Heights',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-village-east',
                content: 'VILLAGE EAST',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128497/Village-East',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-victory-crossing',
                content: 'VICTORY CROSSING',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131734/Victory-Crossing',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-victory-crossing-north',
                content: 'VICTORY CROSSING NORTH',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127089/Victory-Crossing-North',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-victoria',
                content: 'VICTORIA CROSSING',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131654/Victoria-Crossing',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-victoria-ii',
                content: 'VICTORIA CROSSING II',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129747/Victoria-Crossing-II',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-valley',
                content: 'VALLEY CLUB ACRES',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132486/Valley-Club-Acres',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-utah',
                content: 'UTAH PARK',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128318/Utah-Park',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-tuscany',
                content: 'Tuscany',
                url: '"https://www.colohomesforsale.com/neighborhood-detail/128087/Tuscany',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-turnberry',
                content: 'Turnberry',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132262/Turnberry',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-trail-ridge',
                content: 'trail ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827157/trail-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-traditions',
                content: 'Traditions',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128510/Tradition',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-town-center-terrace',
                content: 'Town Center Terrace',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132016/Town-Center-Terrace',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-town-&-country',
                content: 'town & country',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827154/town-&-country',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-tower-triangle',
                content: 'tower triangle',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827153/tower-triangle',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-tower-ridge',
                content: 'tower ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827152/tower-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-tower-park',
                content: 'tower park',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827151/tower-park',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-tower-green',
                content: 'tower green',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827150/tower-green',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-topaz-at-the-mall',
                content: 'topaz at the mall',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827149/topaz-at-the-mall',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-tollgate-overlook',
                content: 'tollgate overlook',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827148/tollgate-overlook',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-tollgate-crossing',
                content: 'tollgate crossing',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827147/tollgate-crossing',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-thunderbird-estates',
                content: 'thunderbird estates',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827146/thunderbird-estates',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-vistas-at-saddle-rock',
                content: 'the vistas at saddle rock',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827145/the-vistas-at-saddle-rock',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-itemthe-vistas-at-meadow-hills-',
                content: 'the vistas at meadow hills',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827144/the-vistas-at-meadow-hills',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-villas',
                content: 'the villas',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827143/the-villas',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-villas-at-great-plains-park',
                content: 'the villas at great plains park',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827142/the-villas-at-great-plains-park',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-',
                content: 'the villas at cherry creek',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827141/the-villas-at-cherry-creek',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-timbers',
                content: 'the timbers',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827140/the-timbers',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-shores',
                content: 'the shores',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827139/the-shores',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-savoyat-dayton-station',
                content: 'the savoyat dayton station',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827138/the-savoyat-dayton-station',
              },
              {
                id: "ugljan-header-neighborhoods-menu-item-the-sanctuary-at-tallyn's-reach",
                content: "the sanctuary at tallyn's reach",
                url: "https://www.colohomesforsale.com/neighborhoods/153827137/the-sanctuary-at-tallyn's-reach",
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-orchard',
                content: 'the orchard',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827136/the-orchard',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-landing-at-cherry-creek',
                content: 'the landing at cherry creek',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827135/the-landing-at-cherry-creek',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-',
                content: 'the knolls at park view',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827134/the-knolls-at-park-view',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-',
                content: 'the hills at piney creek',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827133/the-hills-at-piney-creek',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-highlands-at-piney-creek',
                content: 'the highlands at piney creek',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827132/the-highlands-at-piney-creek',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-grove',
                content: 'the grove',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827131/the-grove',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-greens',
                content: 'the greens',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827130/the-greens',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-farm',
                content: 'the farm',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827129/the-farm',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-dam-west',
                content: 'the dam west',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827128/the-dam-west',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-dam-east',
                content: 'the dam east',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827127/the-dam-east',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-colony-at-cherry-creek',
                content: 'the colony at cherry creek',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827126/the-colony-at-cherry-creek',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-chateaux',
                content: 'the chateaux',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827125/the-chateaux',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-bluffs',
                content: 'the bluffs',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827124/the-bluffs',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-the-aspens',
                content: 'the aspens',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827123/the-aspens',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-telegraph-hill-ii',
                content: 'Telegraph Hill II',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827122/telegraph-hill-ii',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-tallyns-reach',
                content: "Tallyn's Reach",
                url: "https://www.colohomesforsale.com/neighborhoods/153827121/tallyn's-reach",
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-swedish-gardens',
                content: 'Swedish gardens',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827120/swedish-gardens',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sunstone-townhomes',
                content: 'sunstone townhomes',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827119/sunstone-townhomes',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sunstone-north',
                content: 'sunstone north',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827118/sunstone-north',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sunridge',
                content: 'sunridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827117/sunridge',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sunny-vale',
                content: 'sunny vale',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827116/sunny-vale',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sunny-slope',
                content: 'sunny slope',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827115/sunny-slope',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sunlite',
                content: 'sunlite',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827114/sunlite',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sunlite-ridge',
                content: 'sunlite ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827113/sunlite-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sunburst',
                content: 'sunburst',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827112/sunburst',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-summit-at-smoky-hill',
                content: 'summit at smoky hill',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827111/summit-at-smoky-hill',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-summit-park',
                content: 'summit park',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827110/summit-park',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-summerglen',
                content: 'summerglen',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827109/summerglen',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-summerfield-villas',
                content: 'summerfield villas',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827108/summerfield-villas',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-summerhill',
                content: 'summerhill',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827107/summerhill',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-summer-valley',
                content: 'summer-valley',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827106/summer-valley',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-s',
                content: 'summer lakes',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827105/summer-lakes',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-summer-breeze',
                content: 'summer breeze',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827104/summer-breeze',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-strawberry',
                content: 'strawberry',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827103/strawberry',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-stonetree',
                content: 'stonetree',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827102/stonetree',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-stonehouse-farm',
                content: 'stonehouse farm',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827101/stonehouse-farm',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-stone-ridge-park',
                content: 'stone ridge park',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827100/stone-ridge-park',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-stone-cliff',
                content: 'stone cliff',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827099/stone-cliff',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-stone-canyon',
                content: 'stone canyon',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827098/stone-canyon',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sterling-pointe',
                content: 'sterling pointe',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827097/sterling-pointe',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sterling-hills',
                content: 'sterling hills',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827096/sterling-hills',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sterling-commons',
                content: 'sterling commons',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827095/sterling-commons',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sterling-commons-ii',
                content: 'sterling commons ii',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827094/sterling-commons-ii',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-star-pass-of-saddle-rock',
                content: 'star pass of saddle rock',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827093/star-pass-of-saddle-rock',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-stapleton-industrial-park',
                content: 'stapleton industrial park',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827092/stapleton-industrial-park',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-stage-run',
                content: 'stage run',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827091/stage-run',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-spruce-neighborhood-at-copperleaf',
                content: 'spruce neighborhood at copperleaf',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827090/spruce-neighborhood-at-copperleaf',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-spring-creek-meadows',
                content: 'spring creek meadows',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827089/spring-creek-meadows',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-southshore',
                content: 'southshore',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827088/southshore',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-southeast-crossing',
                content: 'southeast crossing',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827087/southeast-crossing',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-south-rose-hill',
                content: 'south rose hill',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827086/south-rose-hill',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sorrel-ranch',
                content: 'sorrel ranch',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827085/sorrel-ranch',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sonoma-resort-at-saddle-rock',
                content: 'sonoma resort at saddle rock',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827084/sonoma-resort-at-saddle-rock',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sommerset-gardens',
                content: 'sommerset gardens',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827083/sommerset-gardens',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-somerset-village',
                content: 'somerset village',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827082/somerset-village',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-smoky-ridge',
                content: 'smoky ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827081/smoky-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-smoky-hill',
                content: 'smoky hill',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827080/smoky-hill',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-skyline-terrace',
                content: 'Skyline terrace',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827079/skyline-terrace',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sienna',
                content: 'sienna',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827078/sienna',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-siena',
                content: 'Siena',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827077/siena',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-side-creek',
                content: 'side creek',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827076/side-creek',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-shiloh-ridge',
                content: 'shiloh ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827075/shiloh-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-shenandoah',
                content: 'shenandoah',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827074/shenandoah',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-shamrock-park',
                content: 'shamrock park',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827073/shamrock-park',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-shadow-creek',
                content: 'shadow creek',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827072/shadow-creek',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-seven-lakes',
                content: 'seven lakes',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827071/seven-lakes',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-seven-hills',
                content: 'seven hills',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827070/seven-hills',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-settlers-village',
                content: 'settlers village',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827069/settlers-village',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-settlers-crossing',
                content: "settler's crossing",
                url: "https://www.colohomesforsale.com/neighborhoods/153827068/settler's-crossing",
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-serenity-ridge',
                content: 'serenity ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827067/serenity-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-second-creek-village',
                content: 'second creek village',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827066/second-creek-village',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-second-creek-farms',
                content: 'second creek farms',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827065/second-creek-farms',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-second-appletree-east',
                content: 'second appletree east',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827064/second-appletree-east',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sandpiper',
                content: 'sandpiper',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827063/sandpiper',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-san-francisco',
                content: 'san francisco',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827062/san-francisco',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-saddle-rock',
                content: 'saddle rock',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827061/saddle-rock',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-saddle-rock-ridge',
                content: 'saddle rock ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827060/saddle-rock-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-saddle-rock-ranches',
                content: 'saddle rock ranches',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827059/saddle-rock-ranches',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-saddle-rock-north',
                content: 'saddle rock north',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827058/saddle-rock-north',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-saddle-rock-highlands',
                content: 'saddle rock highlands',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827057/saddle-rock-highlands',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-saddle-rock-golf-homes',
                content: 'saddle rock golf homes',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827056/saddle-rock-golf-homes',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sable-village',
                content: 'sable village',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827055/sable-village',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sable-ridge',
                content: 'sable ridge',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827054/sable-ridge',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sable-cove',
                content: 'Sable cove',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827053/sable-cove',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-sable-altura-chambers',
                content: 'Sable altura chambers',
                url: 'https://www.colohomesforsale.com/neighborhoods/153827052/sable-altura-chambers',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-rose',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127784/Rose-Hill',
                content: 'ROSE HILL',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-rose-gardens',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132433/Rose-Gardens',
                content: 'ROSE GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-rocky',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131596/Rocky-Ridge',
                content: 'ROCKY RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-rocky-mountain',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132550/Rocky-Mountain-Arsenal-National-Wildlife-Refuge',
                content: 'ROCKY MOUNTAIN ARSENAL NATIONAL WILDLIFE REFUGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-horse',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132039/Rocking-Horse',
                content: 'ROCKING HORSE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-robinwood',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127552/Robinwood-Condominiums',
                content: 'ROBINWOOD CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-ridge',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127445/Ridgeview-Glen',
                content: 'RIDGEVIEW GLEN',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-ridgeview',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132458/Ridgeview-Eagle-Bend',
                content: 'RIDGEVIEW EAGLE BEND',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-reunion',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131511/Reunion',
                content: 'REUNION',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-redbuck',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131686/Redbuck',
                content: 'REDBUCK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-red-sky',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130342/Red-Sky-Condominiums',
                content: 'RED SKY CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-rattlesnake',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128945/Rattlesnake-Ridge',
                content: 'RATTLESNAKE RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-range-view',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129261/Range-View',
                content: 'RANGE VIEW',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-rancho',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131841/Rancho-Manor',
                content: 'RANCHO MANOR',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-rainbow-gardens',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132087/Rainbow-Gardens',
                content: 'RAINBOW GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-quincy',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129442/Quincy-Ridge',
                content: 'QUINCY RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-quincy-lake',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131470/Quincy-Lake',
                content: 'QUINCY LAKE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-quincy-hill',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132358/Quincy-Hill',
                content: 'QUINCY HILL',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-quincy-creek',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132144/Quincy-Creek',
                content: 'QUINCY CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-queensborough',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128325/Queensborough',
                content: 'QUEENSBOROUGH',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-quail-run-condominiums',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129326/Quail-Run-Condominiums',
                content: 'QUAIL RUN CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Ptarmigan-Park',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128523/Ptarmigan-Park',
                content: 'PTARMIGAN PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Pride-Crossing',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129988/Pride-Crossing',
                content: 'PRIDE CROSSING',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Prairie-Ridge-at-Saddle-Rock-East',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129427/Prairie-Ridge-at-Saddle-Rock-East',
                content: 'PRAIRIE RIDGE AT SADDLE ROCK EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Prairie-Farm',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131147/Prairie-Farms',
                content: 'PRAIRIE FARMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Prairie-Creek',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129118/Prairie-Creek',
                content: 'PRAIRIE CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Potomac-Farms',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130683/Potomac-Farms',
                content: 'POTOMAC FARMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Pontiac-Gardens',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131499/Pontiac-Gardens',
                content: 'PONTIAC GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Pontiac-Estates',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127785/Pontiac-Estates',
                content: 'PONTIAC ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Ponderosa',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127000/Ponderosa',
                content: 'PONDEROSA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Plains-Conservation-Center',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131892/Plains-Conservation-Center',
                content: 'PLAINS CONSERVATION CENTER',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Pioneer-Hills',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131891/Pioneer-Hills',
                content: 'PIONEER HILLS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Pinnacle-at-the-Creek',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129285/Pinnacle-at-the-Creek',
                content: 'PINNACLE AT THE CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Piney-Creek',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127530/Piney-Creek',
                content: 'PINEY CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Piney-Creek-Village',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131510/Piney-Creek-Village',
                content: 'PINEY CREEK VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-piney-Creek-Ranches',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128972/Piney-Creek-Ranches',
                content: 'PINEY CREEK RANCHES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Pierpoint-VI',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131566/Pierpoint-VI',
                content: 'PIERPOINT VI',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Pierpoint-7',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128262/Pier-Point-7',
                content: 'PIER POINT 7',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Pheasant-Run',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130451/Pheasant-Run',
                content: 'PHEASANT RUN',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Pheasant-Run-Townhomes',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128601/Pheasant-Run-Townhomes',
                content: 'PHEASANT RUN TOWNHOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Peterson',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129100/Peterson',
                content: 'PETERSON',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Peoria-Villa',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132247/Peoria-Villas',
                content: 'PEORIA VILLAS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Peoria-Park',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128904/Peoria-Park',
                content: 'PEORIA PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Peninsula-Condominiums',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129080/Peninsula-Condominiums',
                content: 'PENINSULA CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Peachwood',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128356/Peachwood',
                content: 'PEACHWOOD',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Parkview',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127516/Parkview',
                content: 'PARKVIEW',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Parkview-Heights',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128094/Parkview-Heights',
                content: 'PARKVIEW HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Parkside-at-Reunion',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129320/Parkside-at-Reunion',
                content: 'PARKSIDE AT REUNION',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Parkborough',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129288/Parkborough',
                content: 'PARKBOROUGH',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Park-Way-Condominiums',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130059/Park-Way-Condominiums',
                content: 'PARK WAY CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Park-Villas',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129336/Park-Villas',
                content: 'PARK VILLAS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Park-View',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128454/Park-View',
                content: 'PARK VIEW',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Park-View-Terrace',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131503/Park-View-Terrace',
                content: 'PARK VIEW TERRACE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Park-View-ridge',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129757/Park-View-Ridge',
                content: 'PARK VIEW RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Park-View-meadows',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128305/Park-View-Meadows',
                content: 'PARK VIEW MEADOWS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Park-View-estate',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128641/Park-View-Estates',
                content: 'PARK VIEW ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Park-Place-Villas',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129721/Park-Place-Villas',
                content: 'PARK PLACE VILLAS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Park-East',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128771/Park-East',
                content: 'PARK EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Park-Avenue',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131960/Park-Avenue',
                content: 'PARK AVENUE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Orchard-Valley',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131582/Orchard-Valley',
                content: 'ORCHARD VALLEY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Oliveglen-Village',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130055/Oliveglen-Village',
                content: 'OLIVEGLEN VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Olde-Towne',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127180/Olde-Towne',
                content: 'OLDE TOWNE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Oakland-Court',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/126994/Oakland-Court',
                content: 'OAKLAND COURT',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-norwood-park',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130165/Norwood-Park',
                content: 'NORWOOD PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-northwest-aurora',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127707/Northwest-Aurora',
                content: 'NORTHWEST AURORA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-northeast',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129833/Northeast',
                content: 'NORTHEAST',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-North-Range-Village',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131570/North-Range-Village',
                content: 'NORTH RANGE VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-North-Range-town',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129172/North-Range-Town-Center',
                content: 'NORTH RANGE TOWN CENTER',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-nortfolk-one',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131415/Norfolk-Glen',
                content: 'NORFOLK GLEN',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-nortfolk-two',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129148/Norfolk-Glen',
                content: 'NORFOLK GLEN',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-1',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/126872/New-World-West',
                content: 'NEW WORLD WEST',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-2',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130421/New-England-Heights',
                content: 'NEW ENGLAND HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-3',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127948/Nevin-Village-Green',
                content: 'NEVIN VILLAGE GREEN',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-4',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128072/Nantucket',
                content: 'NANTUCKET',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-5',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127904/Murphy-Creek',
                content: 'MURPHY CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-6',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128394/Mowrey',
                content: 'MOWREY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-7',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131638/Mountainview-Estates',
                content: 'MOUNTAINVIEW ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-8',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130325/Morris-Heights',
                content: 'MORRIS HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-9',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131196/Moon-Shadow-at-Saddle-Rock',
                content: 'MOON SHADOW AT SADDLE ROCK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-10',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131478/Monaco',
                content: 'MONACO',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-11',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132135/Monaco-Vista',
                content: 'MONACO VISTA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-12',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130142/Mission-Viejo',
                content: 'MISSION VIEJO',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-13',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129480/Mill-Valley',
                content: 'MILL VALLEY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-14',
                content: 'MILL RUN',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132541/Mill-Run',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-15',
                content: 'MESA',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129498/Mesa',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-16',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129421/Memory-Lane-Gardens',
                content: 'MEMORY LANE GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-17',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128984/Meadow-Wood',
                content: 'MEADOW WOOD',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-18',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127803/Meadow-Point-Condominiums',
                content: 'MEADOW POINT CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-19',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130199/Meadow-Hills',
                content: 'MEADOW HILLS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-20',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129614/Manchester-Park',
                content: 'MANCHESTER PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-21',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128026/Mabry',
                content: 'MABRY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-22',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129016/Lynwood-Heights',
                content: 'LYNWOOD HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-23',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130944/Lynn-Knoll',
                content: 'LYNN KNOLL',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-24',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131442/Lynknoll',
                content: 'LYNKNOLL',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-25',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131076/Lyn-Meadows',
                content: 'LYN MEADOWS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-26',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130600/Lyn-Acres',
                content: 'LYN ACRES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-27',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127666/Lydeva-Heights',
                content: 'LYDEVA HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-28',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132107/Louisiana-Purchase-II',
                content: 'LOUISIANA PURCHASE II',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-29',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127935/Linden',
                content: 'LINDEN',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132449/Lexington',
                content: 'LEXINGTON',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-30',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127752/Lexington-East',
                content: 'LEXINGTON EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-31',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131492/Leisure-Landing',
                content: 'LEISURE LANDING',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-32',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132069/Legacy-Park',
                content: 'LEGACY PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-33',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131077/Le-Chateau',
                content: 'LE CHATEAU',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-34',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127332/Lazy-Hills-Ranchettes',
                content: 'LAZY HILLS RANCHETTES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-35',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132540/Laredo-Highline',
                content: 'LAREDO HIGHLINE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-36',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130352/Lakeview-Terrace',
                content: 'LAKEVIEW TERRACE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-37',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127748/Lakeshore',
                content: 'LAKESHORE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-38',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130641/Lake-Pointe',
                content: 'LAKE POINTE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-39',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127778/Lake-Estates',
                content: 'LAKE ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-40',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129590/La-Vista',
                content: 'LA VISTA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-41',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129507/Kragelund-Acres',
                content: 'KRAGELUND ACRES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-42',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127885/Kirkegaard-Acres',
                content: 'KIRKEGAARD ACRES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-43',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127378/Kirkegaard-Acres',
                content: 'KIRKEGAARD ACRES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-44',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127565/Kingsborough',
                content: 'KINGSBOROUGH',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-45',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127627/Kingsborough',
                content: 'KINGSBOROUGH',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-46',
                url: 'https://www.colohomesforsale.com/neighborhoods/153826935/kennedy',
                content: 'KENNEDY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-47',
                url: 'https://www.colohomesforsale.com/neighborhoods/153826934/kennedy',
                content: 'KENNEDY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-48',
                content: 'KEMP',
                url: 'https://www.colohomesforsale.com/neighborhoods/153826933/kemp',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-49',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132110/Kearney-Heights',
                content: 'KEARNEY HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-50',
                content: 'KAREN PARK',
                url: 'https://www.colohomesforsale.com/neighborhoods/153826931/karen-park',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-51',
                content: 'JUDD',
                url: 'https://www.colohomesforsale.com/neighborhoods/153826930/judd',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-52',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128035/Jamaica-Park',
                content: 'JAMAICA PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-53',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130961/Jackson-Farm',
                content: 'JACKSON FARM',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-54',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131273/Ivy-Hill',
                content: 'IVY HILL',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-55',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130918/Irondale',
                content: 'IRONDALE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-56',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132382/Irondale-Gardens',
                content: 'IRONDALE GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-57',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129281/Innsbruck',
                content: 'INNSBRUCK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-58',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132223/Imperial-Park',
                content: 'IMPERIAL PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-59',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131104/Iliff-Homes',
                content: 'ILIFF HOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-60',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131253/Iliff-Commons',
                content: 'ILIFF COMMONS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-61',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132259/Horseshoe-Park',
                content: 'HORSESHOE PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-62',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132074/Horizons',
                content: 'HORIZONS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-63',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128411/Holly-Heights',
                content: 'HOLLY HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-64',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131075/Hoffman-Heights-/-Jewell-Heights',
                content: 'HOFFMAN HEIGHTS / JEWELL HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-65',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127301/Hillside',
                content: 'HILLSIDE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-66',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128439/Hillcrest-Villages',
                content: 'HILLCREST VILLAGES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-67',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127761/Hill-Top',
                content: 'HILL TOP',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-68',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129863/Highpoint',
                content: 'HIGHPOINT',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-69',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132386/Highline-Villages',
                content: 'HIGHLINE VILLAGES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-70',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130313/Highline-Court',
                content: 'HIGHLINE COURT',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-71',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129583/Highline-/-Expo',
                content: 'HIGHLINE / EXPO',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-72',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131535/Highlands-at-Buffalo-Run-East',
                content: 'HIGHLANDS AT BUFFALO RUN EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-73',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127361/Highland-Park',
                content: 'HIGHLAND PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-74',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131361/High-Pointe',
                content: 'HIGH POINTE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-75',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127546/High-Hollows-Condominiums',
                content: 'HIGH HOLLOWS CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-76',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129998/Hidden-Valley',
                content: 'HIDDEN VALLEY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-77',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128521/Heritage-Eagle-Bend',
                content: 'HERITAGE EAGLE BEND',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-78',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131865/Heather-Ridge',
                content: 'HEATHER RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-79',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129792/Heather-Ridge-South',
                content: 'HEATHER RIDGE SOUTH',
              },

              {
                id: 'ugljan-header-neighborhoods-menu-item-80',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127892/Heather-Gardens',
                content: 'HEATHER GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-81',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132139/Hearthstone',
                content: 'HEARTHSTONE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-82',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130079/Hawthorn',
                content: 'HAWTHORN',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-83',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132567/Havana',
                content: 'HAVANA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-84',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130040/Havana-Villa',
                content: 'HAVANA VILLA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-85',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128663/Havana-Park',
                content: 'HAVANA PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-86',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128115/Havana-Heights',
                content: 'HAVANA HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-87',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127600/Harvest-Meadows',
                content: 'HARVEST MEADOWS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-88',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130376/Hampden-Villas',
                content: 'HAMPDEN VILLAS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-89',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127417/Hampden-Towncenter',
                content: 'HAMPDEN TOWNCENTER',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-90',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128228/Hampden-Hills',
                content: 'HAMPDEN HILLS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-91',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128616/Hallcrafts-Village-East',
                content: 'HALLCRAFTS VILLAGE EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-92',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127857/Gun-Club-Estates',
                content: 'GUN CLUB ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-93',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129413/Grout',
                content: 'GROUT',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-94',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127670/Greenfield',
                content: 'GREENFIELD',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-95',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128438/Greenfield-Masters',
                content: 'GREENFIELD MASTERS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-96',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128991/Greenbrook',
                content: 'GREENBROOK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-97',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127030/Granby-Commons',
                content: 'GRANBY COMMONS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-98',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128172/Grahams',
                content: 'GRAHAMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-99',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130467/Gilligan',
                content: 'GILLIGAN',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-100',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129691/Gifford-Gardens',
                content: 'GIFFORD GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-101',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129503/GentryCountry-Homes',
                content: 'GENTRYCOUNTRY HOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-102',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127773/Gateway-Townhomes',
                content: 'GATEWAY TOWNHOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-103',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129710/Gateway-Park',
                content: 'GATEWAY PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-104',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132015/Garden-Heights',
                content: 'GARDEN HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-105',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129840/Gamble',
                content: 'GAMBLE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-106',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129547/Gamble-Derby',
                content: 'GAMBLE DERBY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-107',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129130/Fronterra-Village',
                content: 'FRONTERRA VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-108',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127961/Fronterra-Village-Townhomes',
                content: 'FRONTERRA VILLAGE TOWNHOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-109',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128697/Friendly-Village',
                content: 'FRIENDLY VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-110',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129563/French-Creek',
                content: 'FRENCH CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-111',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127625/Foxton-Village',
                content: 'FOXTON VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-112',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131388/Foxfield',
                content: 'FOXFIELD',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-113',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129579/Foxdale-Condominiums',
                content: 'FOXDALE CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-114',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131365/Fox-Hill',
                content: 'FOX HILL',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-115',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128293/Fountain-Side',
                content: 'FOUNTAIN SIDE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-116',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128248/Fitzsimons',
                content: 'FITZSIMONS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-117',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127168/First-Creek-Farm',
                content: 'FIRST CREEK FARM',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-118',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132201/Finkbeiner',
                content: 'FINKBEINER',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-119',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128823/Fernald-/-Frei-Crossing',
                content: 'FERNALD / FREI CROSSING',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-120',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132116/Fairway',
                content: 'FAIRWAY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-121',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129796/Fairway-Village',
                content: 'FAIRWAY VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-122',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128129/Fairfield-Village',
                content: 'FAIRFIELD VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-123',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128482/Fairfax-Park',
                content: 'FAIRFAX PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-124',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127076/Eureka',
                content: 'EUREKA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-125',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128890/Estancia',
                content: 'ESTANCIA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-126',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127742/Embarcadero-in-Willowridge',
                content: 'EMBARCADERO IN WILLOWRIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-127',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132046/Elk-Ridge',
                content: 'ELK RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-128',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128095/Eastwood-Townhomes',
                content: 'EASTWOOD TOWNHOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-129',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131432/East-Ridge-/-Ptarmigan-Park',
                content: 'EAST RIDGE / PTARMIGAN PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-130',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128979/East-Quincy-Highlands',
                content: 'EAST QUINCY HIGHLANDS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-131',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128694/East-Colfax',
                content: 'EAST COLFAX',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-132',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/126982/Eagle-Village',
                content: 'EAGLE VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-133',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128800/Eagle-Creek',
                content: 'EAGLE CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-134',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127968/Dupont',
                content: 'DUPONT',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-135',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/126907/Dover',
                content: 'DOVER',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-136',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130191/Dove-Hill',
                content: 'DOVE HILL',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-137',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129143/Derby',
                content: 'DERBY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-138',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131787/Denver-International-Tech-Center',
                content: 'DENVER INTERNATIONAL TECH CENTER',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-139',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129581/Denver-International-Airport',
                content: 'DENVER INTERNATIONAL AIRPORT',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-140',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131171/Del-Mar',
                content: 'DEL MAR',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-141',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131949/Del-Mar-Parkway',
                content: 'DEL MAR PARKWAY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-142',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131651/Del-Mar-Park-Condominiums',
                content: 'DEL MAR PARK CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-143',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128919/Deer-Pointe-Village-Condominiums',
                content: 'DEER POINTE VILLAGE CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-144',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128166/Daybreak',
                content: 'DAYBREAK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-145',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128854/Dam-East-/-West',
                content: 'DAM EAST / WEST',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-146',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129065/Cutler',
                content: 'CUTLER',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-147',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128168/Crown-Point',
                content: 'CROWN POINT',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-148',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128867/Cross-Creek',
                content: 'CROSS CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-149',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131645/Crestridge',
                content: 'CRESTRIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-150',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129500/Creekview',
                content: 'CREEKVIEW',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-151',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128675/Creekside-Eagle-Bend',
                content: 'CREEKSIDE EAGLE BEND',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-152',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128323/Coyote-Ranch',
                content: 'COYOTE RANCH',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-153',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127782/Country-Village',
                content: 'COUNTRY VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-154',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131661/Country-Lane',
                content: 'COUNTRY LANE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-155',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132200/Country-Green',
                content: 'COUNTRY GREEN',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-156',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132411/Country-Club-Village',
                content: 'COUNTRY CLUB VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-157',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131589/Cottonwood',
                content: 'COTTONWOOD',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-158',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129598/Copperleaf',
                content: 'COPPERLEAF',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-159',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129094/Copper-Ridge',
                content: 'COPPER RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-160',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129523/Conter-Estates',
                content: 'CONTER ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-161',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128126/Conservatory',
                content: 'CONSERVATORY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-162',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127486/Columbine-Ranches',
                content: 'COLUMBINE RANCHES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-163',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129502/Colfax-Villa',
                content: 'COLFAX VILLA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-164',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129023/Cobblewood',
                content: 'COBBLEWOOD',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-165',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129677/Clermont',
                content: 'CLERMONT',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-166',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128915/Churchill',
                content: 'CHURCHILL',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-167',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131124/Cherry',
                content: 'CHERRY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-168',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132543/Cherry-Grove-East',
                content: 'CHERRY GROVE EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-169',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131366/Cherry-Creek-Rancho',
                content: 'CHERRY CREEK RANCHO',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-170',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127097/Cherry-Creek-Racquet-Club',
                content: 'CHERRY CREEK RACQUET CLUB',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-171',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127080/Cherry-Creek-East',
                content: 'CHERRY CREEK EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-172',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130801/Cherry-Brook',
                content: 'CHERRY BROOK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-173',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131255/Cherokee',
                content: 'CHEROKEE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-174',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127470/Chenango',
                content: 'CHENANGO',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-175',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128140/Chelsea',
                content: 'CHELSEA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-176',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132131/Chelsea-Park',
                content: 'CHELSEA PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-177',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129240/Charleston-Place-Condominiums',
                content: 'CHARLESTON PLACE CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-178',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130700/Chapparal',
                content: 'CHAPPARAL',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-179',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127769/Chapman',
                content: 'CHAPMAN',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-180',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129560/Champagne',
                content: 'CHAMPAGNE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-181',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129587/Chambers-Ridge',
                content: 'CHAMBERS RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-182',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128235/Chambers-Heights',
                content: 'CHAMBERS HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-183',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128066/Chambers-Estates',
                content: 'CHAMBERS ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-184',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131935/Chaddsford',
                content: 'CHADDSFORD',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-185',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128628/Century-City-Condominiums',
                content: 'CENTURY CITY CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-186',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130356/Centretech',
                content: 'CENTRETECH',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-187',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127528/Central',
                content: 'CENTRAL',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-188',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132094/Central-Park-Townhomes',
                content: 'CENTRAL PARK TOWNHOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-189',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127014/Center-Pointe',
                content: 'CENTER POINTE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-190',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129532/Cedar-Cove',
                content: 'CEDAR COVE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-191',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132404/Carriage-Village',
                content: 'CARRIAGE VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-192',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128862/Carriage-Place',
                content: 'CARRIAGE PLACE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-193',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128501/Carnation-Meadows',
                content: 'CARNATION MEADOWS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-194',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129604/Burns-Aurora',
                content: 'BURNS AURORA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-195',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128494/Buffalo-Mesa',
                content: 'BUFFALO MESA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-196',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132409/Buffalo-Highlands',
                content: 'BUFFALO HIGHLANDS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-197',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127273/Buckley-Ranch',
                content: 'BUCKLEY RANCH',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-198',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131201/Buckley-Air-Force-Base',
                content: 'BUCKLEY AIR FORCE BASE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-199',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/126916/Buckingham-Oaks-Condominiums',
                content: 'BUCKINGHAM OAKS CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-200',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128565/Brookvale',
                content: 'BROOKVALE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-201',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131907/Brookhaven-Condominiums',
                content: 'BROOKHAVEN CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-202',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130071/Broadstone-Cornerstone',
                content: 'BROADSTONE CORNERSTONE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-203',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128056/Brittany-Highlands',
                content: 'BRITTANY HIGHLANDS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-204',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127123/Briarwood',
                content: 'BRIARWOOD',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-205',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129189/Breakaway-Condominiums',
                content: 'BREAKAWAY CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-206',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129457/Brandychase',
                content: 'BRANDYCHASE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-207',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129635/Brandychase-Condominiums',
                content: 'BRANDYCHASE CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-208',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132256/Brandon-Park-Condominiums',
                content: 'BRANDON PARK CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-209',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128186/Bradell-Estates',
                content: 'BRADELL ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-210',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131488/Bowen',
                content: 'BOWEN',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-211',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127890/Bogner',
                content: 'BOGNER',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-212',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132600/Blackstone',
                content: 'BLACKSTONE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-213',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129888/Black-Hawk-Derby',
                content: 'BLACK HAWK DERBY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-214',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127416/Beverly-Homes',
                content: 'BEVERLY HOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-215',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128337/Bestview-Acres',
                content: 'BESTVIEW ACRES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-216',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128648/Berkshire',
                content: 'BERKSHIRE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-217',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127428/Berkshire-Place',
                content: 'BERKSHIRE PLACE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-218',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132138/Bel-Aire-Estates',
                content: 'BEL-AIRE ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-219',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129568/Beacon-Point',
                content: 'BEACON POINT',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-220',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130753/Bayberry-Condominiums',
                content: 'BAYBERRY CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-221',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127753/Barr-Lake-Estates',
                content: 'BARR LAKE ESTATES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-222',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129215/Banyon-Hollow-Condominiums',
                content: 'BANYON HOLLOW CONDOMINIUMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-223',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131528/Avalon',
                content: 'AVALON',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-224',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130908/Aurora-Vista',
                content: 'AURORA VISTA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-225',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132129/Aurora-Vista',
                content: 'AURORA VISTA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-226',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130104/Aurora-Meadows',
                content: 'AURORA MEADOWS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-227',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127058/Aurora-Knolls',
                content: 'AURORA KNOLLS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aurora-Knolls-South',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127466/Aurora-Knolls-South',
                content: 'AURORA KNOLLS SOUTH',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Hutchinson-Heights',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131050/Aurora-Knolls-/-Hutchinson-Heights',
                content: 'AURORA KNOLLS / HUTCHINSON HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aurora-Hills',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130577/Aurora-Hills',
                content: 'AURORA HILLS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aurora-Hills-Golf-Course',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127443/Aurora-Hills-Golf-Course',
                content: 'AURORA HILLS GOLF COURSE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aurora-Highlands',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127373/Aurora-Highlands',
                content: 'AURORA HIGHLANDS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aurora-Highlands',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132215/Aurora-Highlands',
                content: 'AURORA HIGHLANDS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aurora-Heights',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130152/Aurora-Heights',
                content: 'AURORA HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aurora-East-Village',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130359/Aurora-East-Village',
                content: 'AURORA EAST VILLAGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aurora-East-Planned-Community',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127931/Aurora-East-Planned-Community',
                content: 'AURORA EAST PLANNED COMMUNITY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aurora-City-Center',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128781/Aurora-City-Center',
                content: 'AURORA CITY CENTER',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aurora-City-Center-North',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128443/Aurora-City-Center-North',
                content: 'AURORA CITY CENTER NORTH',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aurora-Cascades',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131233/Aurora-Cascades',
                content: 'AURORA CASCADES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Auburn-Hill',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127943/Auburn-Hill',
                content: 'AUBURN HILL',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aspen-ridge',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128321/Aspen-Ridge',
                content: 'ASPEN RIDGE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aspen-hills',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127791/Aspen-Hills',
                content: 'ASPEN HILLS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aspen-Creek',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/126893/Aspen-Creek',
                content: 'ASPEN CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Asbury-Townhomes',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130110/Asbury-Townhomes',
                content: 'ASBURY TOWNHOMES',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Arapahoe-Meadows',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127671/Arapahoe-Meadows',
                content: 'ARAPAHOE MEADOWS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Arapahoe-Heights',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128271/Arapahoe-Heights',
                content: 'ARAPAHOE HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Appletree-East',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131560/Appletree-East',
                content: 'APPLETREE EAST',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Apache-Mesa',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130713/Apache-Mesa',
                content: 'APACHE MESA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Apache-Meadows',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128878/Apache-Meadows',
                content: 'APACHE MEADOWS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Antelope',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129831/Antelope',
                content: 'ANTELOPE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Andover-Glen-at-Chery-Creek',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131402/Andover-Glen-at-Chery-Creek',
                content: 'ANDOVER GLEN AT CHERY CREEK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Anderson',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127780/Anderson',
                content: 'ANDERSON',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Americana',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130160/Americana',
                content: 'AMERICANA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Alvarado-Place',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131649/Alvarado-Place',
                content: 'ALVARADO PLACE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Allied',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131484/Allred',
                content: 'ALLRED',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Allied-Waste',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132090/Allied-Waste',
                content: 'ALLIED WASTE',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Albeck',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/127399/Albeck',
                content: 'ALBECK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Advance-Mobile-Park',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128170/Advance-Mobile-Park',
                content: 'ADVANCE MOBILE PARK',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Adonea',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/132096/Adonea',
                content: 'ADONEA',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Adams-Heights',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130867/Adams-Heights',
                content: 'ADAMS HEIGHTS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Adams-City',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128589/Adams-City',
                content: 'ADAMS CITY',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Adams-City-Poultry-Farms',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/131794/Adams-City-Poultry-Farms',
                content: 'ADAMS CITY POULTRY FARMS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Adams-City-Gardens',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/130000/Adams-City-Gardens',
                content: 'ADAMS CITY GARDENS',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-Aamodt',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/129773/Aamodt',
                content: 'AAMODT',
              },
              {
                id: 'ugljan-header-neighborhoods-menu-item-56th-Avenue-Industrial-Park',
                url: 'https://www.colohomesforsale.com/neighborhood-detail/128277/56th-Avenue-Industrial-Park',
                content: '56TH AVENUE INDUSTRIAL PARK',
              },
            ],
          },
          {
            id: 'ugljan-mobile-sold-listings-item',
            content: 'SOLD LISTINGS',
            url: 'https://www.colohomesforsale.com/sold-listing',
          },
          {
            id: 'ugljan-mobile-exclusive-trade-item',
            url: 'https://trade-in.colohomesforsale.com/',
            content: 'EXCLUSIVE TRADE-IN PROGRAM',
          },
          {
            id: 'ugljan-mobile-contact-item',
            content: 'CONTACT',
            url: 'https://www.colohomesforsale.com/contact',
          },
          {
            id: 'ugljan-mobile-cash-offers-item',
            url: 'https://cash-offer-ugljan.netlify.app/',
            content: 'CASH OFFERS',
          },
          {
            id: 'ugljan-mobile-review-item',
            content: 'REVIEW',
            url: 'https://www.colohomesforsale.com/reviews',
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'main-footer',
      className: 'on-ugljan-footer ugljan-main-footer',
      sectionClassName: 'on-ugljan-main-footer-container',
      columns: [
        {
          id: 'ugljan-footer-logo-column',
          className: 'on-ugljan-footer-logo-column',
          items: [
            {
              id: 'ugljan-footer-logo',
              type: 'image',
              className: '',
              url: 'https://si-homelight.s3.amazonaws.com/sites/ugljan/logo.png',
            },
            {
              id: 'ugljan-co-name',
              type: 'heading',
              elementType: 'h2',
              data: 'RE/MAX MASTERS MILLENNIUM',
            },
          ],
        },
        {
          id: 'ugljan-footer-info-column',
          className: 'on-ugljan-footer-info-column',
          items: [
            {
              id: 'ugljan-footer-company-info',
              type: 'socialIcons',
              className: 'on-ugljan-footer-company-info',
              items: [
                {
                  id: 'ugljan-footer-user',
                  iconTypeClassName: 'fas',
                  iconClassName: 'user',
                  data: 'Robert Hryniewich',
                },
                {
                  id: 'ugljan-footer-phone',
                  iconTypeClassName: 'fas',
                  iconClassName: 'phone-alt',
                  data: '303-564-8900',
                  url: 'tel:3035648900',
                },
                {
                  id: 'ugljan-footer-email',
                  iconTypeClassName: 'fas',
                  iconClassName: 'envelope',
                  data: 'sales@colohomesforsale.com',
                  url: 'mailto:sales@colohomesforsale.com',
                },
                {
                  id: 'ugljan-footer-address',
                  iconTypeClassName: 'fas',
                  iconClassName: 'address-card',
                  data: 'FA 40039371',
                },
                {
                  id: 'ugljan-footer-location',
                  iconTypeClassName: 'fas',
                  iconClassName: 'map-marker-alt',
                  data: '6020 Greenwood Plaza Blvd Suite 100 Greenwood Village, CO 80111',
                },
              ],
            },
            {
              id: 'ugljan-footer-main-menu',
              type: 'menu',
              className: 'on-ugljan-footer-menu',
              itemClassName: 'on-ugljan-footer-menu-item',
              items: [
                {
                  id: 'footer-menu-item-listings',
                  data: 'LISTINGS',
                  submenuClassName: '',
                  submenuItemsClassName: 'on-ugljan-submenu-items',
                  submenuLabelClassName: 'on-ugljan-submenu-label',
                  submenuItems: [
                    {
                      id: 'ugljan-footer-listings-item-all-listings',
                      data: 'ALL LISTINGS',
                      url: 'https://www.colohomesforsale.com/listing',
                    },
                    {
                      id: 'ugljan-footer-listings-item-featured-listings',
                      data: 'FEATURED LISTINGS',
                      url: 'https://www.colohomesforsale.com/featured-listing',
                    },
                  ],
                },
                {
                  id: 'footer-menu-item-selling',
                  data: 'SELLING',
                  submenuClassName: '',
                  submenuItemsClassName: 'on-ugljan-submenu-items',
                  submenuLabelClassName: 'on-ugljan-submenu-label',
                  submenuItems: [
                    {
                      id: 'ugljan-footer-selling-item-sell-my-home',
                      data: 'SELL MY HOME',
                      url: 'https://www.colohomesforsale.com/sell',
                    },
                    {
                      id: 'ugljan-footer-selling-item-home-evaluation',
                      data: 'HOME EVALUATION',
                      url: 'https://www.colohomesforsale.com/evaluation',
                    },
                  ],
                },
                {
                  id: 'footer-menu-item-about',
                  data: 'ABOUT',
                  url: 'https://www.colohomesforsale.com/about',
                },
                {
                  id: 'footer-menu-item-neighborhoods',
                  data: 'NEIGHBORHOODS',
                  submenuClassName: '',
                  submenuItemsClassName: 'on-ugljan-submenu-items',
                  submenuLabelClassName: 'on-ugljan-submenu-label',
                  submenuItems: [
                    {
                      id: 'ugljan-footer-neighborhoods-item-zurchers',
                      data: 'ZURCHERS',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130541/Zurchers',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-yacovetta',
                      data: 'YACOVETTA',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128429/Yacovetta',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-woodrim-crossing',
                      url: 'www.colohomesforsale.com/neighborhood-detail/127093/Woodrim-Crossing',
                      data: 'WOODRIM CROSSING',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-woodgate',
                      data: 'WOODGATE',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128484/Woodgate',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-windsor',
                      data: 'WINDSOR AT MEADOW HILLS',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130810/Windsor-at-Meadow-Hills"',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-windsor-park',
                      data: 'WINDSOR PARK',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131254/Windsor-Park',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-windmill',
                      data: 'WINDMILL CREEK RESERVE',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129611/Windmill-Creek-Reserve',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-',
                      data: 'WINDCREEK CONDOMINIUMS',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130978/Windcreek-Condominiums',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-winchester',
                      data: 'WINCHESTER',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128291/Winchester',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-willowridge',
                      data: 'WILLOWRIDGE TOWNHOMES',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130058/Willowridge-Townhomes',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-willow-trace',
                      data: 'WILLOW TRACE',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130794/Willow-Trace',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-willow-park',
                      data: 'WILLOW PARK',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130472/Willow-Park',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-wildwood',
                      data: 'WILDWOOD',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129027/Wildwood',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-wilco',
                      data: 'WILCO',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127978/Wilco',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-wikiup',
                      data: 'WIKIUP',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131232/Wikiup',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-wheatlands',
                      data: 'WHEATLANDS',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131495/Wheatlands',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-waters-edge',
                      data: 'WATERS EDGE',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127251/Waters-Edge',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-waterfield',
                      data: 'WATERFIELD COURT',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131549/Waterfield-Court',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-virginia-heights',
                      data: 'VIRGINIA HEIGHTS',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131763/Virginia-Heights',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-village-east',
                      data: 'VILLAGE EAST',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128497/Village-East',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-victory-crossing',
                      data: 'VICTORY CROSSING',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131734/Victory-Crossing',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-victory-crossing-north',
                      data: 'VICTORY CROSSING NORTH',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127089/Victory-Crossing-North',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-victoria',
                      data: 'VICTORIA CROSSING',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131654/Victoria-Crossing',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-victoria-ii',
                      data: 'VICTORIA CROSSING II',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129747/Victoria-Crossing-II',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-valley',
                      data: 'VALLEY CLUB ACRES',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132486/Valley-Club-Acres',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-utah',
                      data: 'UTAH PARK',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128318/Utah-Park',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-tuscany',
                      data: 'Tuscany',
                      url: '"https://www.colohomesforsale.com/neighborhood-detail/128087/Tuscany',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-turnberry',
                      data: 'Turnberry',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132262/Turnberry',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-trail-ridge',
                      data: 'trail ridge',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827157/trail-ridge',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-traditions',
                      data: 'Traditions',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128510/Tradition',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-town-center-terrace',
                      data: 'Town Center Terrace',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132016/Town-Center-Terrace',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-town-&-country',
                      data: 'town & country',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827154/town-&-country',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-tower-triangle',
                      data: 'tower triangle',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827153/tower-triangle',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-tower-ridge',
                      data: 'tower ridge',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827152/tower-ridge',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-tower-park',
                      data: 'tower park',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827151/tower-park',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-tower-green',
                      data: 'tower green',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827150/tower-green',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-topaz-at-the-mall',
                      data: 'topaz at the mall',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827149/topaz-at-the-mall',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-tollgate-overlook',
                      data: 'tollgate overlook',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827148/tollgate-overlook',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-tollgate-crossing',
                      data: 'tollgate crossing',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827147/tollgate-crossing',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-thunderbird-estates',
                      data: 'thunderbird estates',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827146/thunderbird-estates',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-vistas-at-saddle-rock',
                      data: 'the vistas at saddle rock',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827145/the-vistas-at-saddle-rock',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-itemthe-vistas-at-meadow-hills-',
                      data: 'the vistas at meadow hills',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827144/the-vistas-at-meadow-hills',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-villas',
                      data: 'the villas',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827143/the-villas',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-villas-at-great-plains-park',
                      data: 'the villas at great plains park',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827142/the-villas-at-great-plains-park',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-',
                      data: 'the villas at cherry creek',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827141/the-villas-at-cherry-creek',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-timbers',
                      data: 'the timbers',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827140/the-timbers',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-shores',
                      data: 'the shores',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827139/the-shores',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-savoyat-dayton-station',
                      data: 'the savoyat dayton station',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827138/the-savoyat-dayton-station',
                    },
                    {
                      id: "ugljan-footer-neighborhoods-item-the-sanctuary-at-tallyn's-reach",
                      data: "the sanctuary at tallyn's reach",
                      url: "https://www.colohomesforsale.com/neighborhoods/153827137/the-sanctuary-at-tallyn's-reach",
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-orchard',
                      data: 'the orchard',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827136/the-orchard',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-landing-at-cherry-creek',
                      data: 'the landing at cherry creek',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827135/the-landing-at-cherry-creek',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-',
                      data: 'the knolls at park view',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827134/the-knolls-at-park-view',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-',
                      data: 'the hills at piney creek',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827133/the-hills-at-piney-creek',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-highlands-at-piney-creek',
                      data: 'the highlands at piney creek',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827132/the-highlands-at-piney-creek',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-grove',
                      data: 'the grove',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827131/the-grove',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-greens',
                      data: 'the greens',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827130/the-greens',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-farm',
                      data: 'the farm',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827129/the-farm',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-dam-west',
                      data: 'the dam west',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827128/the-dam-west',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-dam-east',
                      data: 'the dam east',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827127/the-dam-east',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-colony-at-cherry-creek',
                      data: 'the colony at cherry creek',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827126/the-colony-at-cherry-creek',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-chateaux',
                      data: 'the chateaux',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827125/the-chateaux',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-bluffs',
                      data: 'the bluffs',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827124/the-bluffs',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-the-aspens',
                      data: 'the aspens',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827123/the-aspens',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-telegraph-hill-ii',
                      data: 'Telegraph Hill II',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827122/telegraph-hill-ii',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-tallyns-reach',
                      data: "Tallyn's Reach",
                      url: "https://www.colohomesforsale.com/neighborhoods/153827121/tallyn's-reach",
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-swedish-gardens',
                      data: 'Swedish gardens',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827120/swedish-gardens',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sunstone-townhomes',
                      data: 'sunstone townhomes',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827119/sunstone-townhomes',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sunstone-north',
                      data: 'sunstone north',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827118/sunstone-north',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sunridge',
                      data: 'sunridge',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827117/sunridge',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sunny-vale',
                      data: 'sunny vale',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827116/sunny-vale',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sunny-slope',
                      data: 'sunny slope',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827115/sunny-slope',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sunlite',
                      data: 'sunlite',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827114/sunlite',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sunlite-ridge',
                      data: 'sunlite ridge',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827113/sunlite-ridge',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sunburst',
                      data: 'sunburst',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827112/sunburst',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-summit-at-smoky-hill',
                      data: 'summit at smoky hill',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827111/summit-at-smoky-hill',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-summit-park',
                      data: 'summit park',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827110/summit-park',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-summerglen',
                      data: 'summerglen',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827109/summerglen',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-summerfield-villas',
                      data: 'summerfield villas',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827108/summerfield-villas',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-summerhill',
                      data: 'summerhill',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827107/summerhill',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-summer-valley',
                      data: 'summer-valley',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827106/summer-valley',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-s',
                      data: 'summer lakes',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827105/summer-lakes',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-summer-breeze',
                      data: 'summer breeze',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827104/summer-breeze',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-strawberry',
                      data: 'strawberry',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827103/strawberry',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-stonetree',
                      data: 'stonetree',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827102/stonetree',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-stonehouse-farm',
                      data: 'stonehouse farm',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827101/stonehouse-farm',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-stone-ridge-park',
                      data: 'stone ridge park',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827100/stone-ridge-park',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-stone-cliff',
                      data: 'stone cliff',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827099/stone-cliff',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-stone-canyon',
                      data: 'stone canyon',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827098/stone-canyon',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sterling-pointe',
                      data: 'sterling pointe',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827097/sterling-pointe',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sterling-hills',
                      data: 'sterling hills',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827096/sterling-hills',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sterling-commons',
                      data: 'sterling commons',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827095/sterling-commons',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sterling-commons-ii',
                      data: 'sterling commons ii',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827094/sterling-commons-ii',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-star-pass-of-saddle-rock',
                      data: 'star pass of saddle rock',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827093/star-pass-of-saddle-rock',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-stapleton-industrial-park',
                      data: 'stapleton industrial park',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827092/stapleton-industrial-park',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-stage-run',
                      data: 'stage run',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827091/stage-run',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-spruce-neighborhood-at-copperleaf',
                      data: 'spruce neighborhood at copperleaf',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827090/spruce-neighborhood-at-copperleaf',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-spring-creek-meadows',
                      data: 'spring creek meadows',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827089/spring-creek-meadows',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-southshore',
                      data: 'southshore',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827088/southshore',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-southeast-crossing',
                      data: 'southeast crossing',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827087/southeast-crossing',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-south-rose-hill',
                      data: 'south rose hill',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827086/south-rose-hill',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sorrel-ranch',
                      data: 'sorrel ranch',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827085/sorrel-ranch',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sonoma-resort-at-saddle-rock',
                      data: 'sonoma resort at saddle rock',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827084/sonoma-resort-at-saddle-rock',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sommerset-gardens',
                      data: 'sommerset gardens',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827083/sommerset-gardens',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-somerset-village',
                      data: 'somerset village',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827082/somerset-village',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-smoky-ridge',
                      data: 'smoky ridge',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827081/smoky-ridge',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-smoky-hill',
                      data: 'smoky hill',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827080/smoky-hill',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-skyline-terrace',
                      data: 'Skyline terrace',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827079/skyline-terrace',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sienna',
                      data: 'sienna',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827078/sienna',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-siena',
                      data: 'Siena',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827077/siena',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-side-creek',
                      data: 'side creek',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827076/side-creek',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-shiloh-ridge',
                      data: 'shiloh ridge',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827075/shiloh-ridge',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-shenandoah',
                      data: 'shenandoah',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827074/shenandoah',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-shamrock-park',
                      data: 'shamrock park',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827073/shamrock-park',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-shadow-creek',
                      data: 'shadow creek',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827072/shadow-creek',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-seven-lakes',
                      data: 'seven lakes',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827071/seven-lakes',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-seven-hills',
                      data: 'seven hills',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827070/seven-hills',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-settlers-village',
                      data: 'settlers village',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827069/settlers-village',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-settlers-crossing',
                      data: "settler's crossing",
                      url: "https://www.colohomesforsale.com/neighborhoods/153827068/settler's-crossing",
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-serenity-ridge',
                      data: 'serenity ridge',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827067/serenity-ridge',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-second-creek-village',
                      data: 'second creek village',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827066/second-creek-village',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-second-creek-farms',
                      data: 'second creek farms',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827065/second-creek-farms',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-second-appletree-east',
                      data: 'second appletree east',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827064/second-appletree-east',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sandpiper',
                      data: 'sandpiper',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827063/sandpiper',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-san-francisco',
                      data: 'san francisco',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827062/san-francisco',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-saddle-rock',
                      data: 'saddle rock',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827061/saddle-rock',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-saddle-rock-ridge',
                      data: 'saddle rock ridge',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827060/saddle-rock-ridge',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-saddle-rock-ranches',
                      data: 'saddle rock ranches',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827059/saddle-rock-ranches',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-saddle-rock-north',
                      data: 'saddle rock north',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827058/saddle-rock-north',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-saddle-rock-highlands',
                      data: 'saddle rock highlands',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827057/saddle-rock-highlands',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-saddle-rock-golf-homes',
                      data: 'saddle rock golf homes',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827056/saddle-rock-golf-homes',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sable-village',
                      data: 'sable village',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827055/sable-village',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sable-ridge',
                      data: 'sable ridge',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827054/sable-ridge',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sable-cove',
                      data: 'Sable cove',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827053/sable-cove',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-sable-altura-chambers',
                      data: 'Sable altura chambers',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153827052/sable-altura-chambers',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-rose',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127784/Rose-Hill',
                      data: 'ROSE HILL',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-rose-gardens',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132433/Rose-Gardens',
                      data: 'ROSE GARDENS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-rocky',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131596/Rocky-Ridge',
                      data: 'ROCKY RIDGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-rocky-mountain',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132550/Rocky-Mountain-Arsenal-National-Wildlife-Refuge',
                      data: 'ROCKY MOUNTAIN ARSENAL NATIONAL WILDLIFE REFUGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-horse',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132039/Rocking-Horse',
                      data: 'ROCKING HORSE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-robinwood',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127552/Robinwood-Condominiums',
                      data: 'ROBINWOOD CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-ridge',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127445/Ridgeview-Glen',
                      data: 'RIDGEVIEW GLEN',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-ridgeview',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132458/Ridgeview-Eagle-Bend',
                      data: 'RIDGEVIEW EAGLE BEND',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-reunion',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131511/Reunion',
                      data: 'REUNION',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-redbuck',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131686/Redbuck',
                      data: 'REDBUCK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-red-sky',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130342/Red-Sky-Condominiums',
                      data: 'RED SKY CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-rattlesnake',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128945/Rattlesnake-Ridge',
                      data: 'RATTLESNAKE RIDGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-range-view',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129261/Range-View',
                      data: 'RANGE VIEW',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-rancho',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131841/Rancho-Manor',
                      data: 'RANCHO MANOR',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-rainbow-gardens',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132087/Rainbow-Gardens',
                      data: 'RAINBOW GARDENS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-quincy',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129442/Quincy-Ridge',
                      data: 'QUINCY RIDGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-quincy-lake',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131470/Quincy-Lake',
                      data: 'QUINCY LAKE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-quincy-hill',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132358/Quincy-Hill',
                      data: 'QUINCY HILL',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-quincy-creek',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132144/Quincy-Creek',
                      data: 'QUINCY CREEK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-queensborough',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128325/Queensborough',
                      data: 'QUEENSBOROUGH',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-quail-run-condominiums',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129326/Quail-Run-Condominiums',
                      data: 'QUAIL RUN CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Ptarmigan-Park',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128523/Ptarmigan-Park',
                      data: 'PTARMIGAN PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Pride-Crossing',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129988/Pride-Crossing',
                      data: 'PRIDE CROSSING',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Prairie-Ridge-at-Saddle-Rock-East',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129427/Prairie-Ridge-at-Saddle-Rock-East',
                      data: 'PRAIRIE RIDGE AT SADDLE ROCK EAST',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Prairie-Farm',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131147/Prairie-Farms',
                      data: 'PRAIRIE FARMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Prairie-Creek',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129118/Prairie-Creek',
                      data: 'PRAIRIE CREEK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Potomac-Farms',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130683/Potomac-Farms',
                      data: 'POTOMAC FARMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Pontiac-Gardens',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131499/Pontiac-Gardens',
                      data: 'PONTIAC GARDENS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Pontiac-Estates',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127785/Pontiac-Estates',
                      data: 'PONTIAC ESTATES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Ponderosa',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127000/Ponderosa',
                      data: 'PONDEROSA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Plains-Conservation-Center',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131892/Plains-Conservation-Center',
                      data: 'PLAINS CONSERVATION CENTER',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Pioneer-Hills',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131891/Pioneer-Hills',
                      data: 'PIONEER HILLS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Pinnacle-at-the-Creek',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129285/Pinnacle-at-the-Creek',
                      data: 'PINNACLE AT THE CREEK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Piney-Creek',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127530/Piney-Creek',
                      data: 'PINEY CREEK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Piney-Creek-Village',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131510/Piney-Creek-Village',
                      data: 'PINEY CREEK VILLAGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-piney-Creek-Ranches',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128972/Piney-Creek-Ranches',
                      data: 'PINEY CREEK RANCHES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Pierpoint-VI',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131566/Pierpoint-VI',
                      data: 'PIERPOINT VI',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Pierpoint-7',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128262/Pier-Point-7',
                      data: 'PIER POINT 7',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Pheasant-Run',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130451/Pheasant-Run',
                      data: 'PHEASANT RUN',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Pheasant-Run-Townhomes',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128601/Pheasant-Run-Townhomes',
                      data: 'PHEASANT RUN TOWNHOMES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Peterson',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129100/Peterson',
                      data: 'PETERSON',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Peoria-Villa',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132247/Peoria-Villas',
                      data: 'PEORIA VILLAS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Peoria-Park',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128904/Peoria-Park',
                      data: 'PEORIA PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Peninsula-Condominiums',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129080/Peninsula-Condominiums',
                      data: 'PENINSULA CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Peachwood',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128356/Peachwood',
                      data: 'PEACHWOOD',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Parkview',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127516/Parkview',
                      data: 'PARKVIEW',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Parkview-Heights',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128094/Parkview-Heights',
                      data: 'PARKVIEW HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Parkside-at-Reunion',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129320/Parkside-at-Reunion',
                      data: 'PARKSIDE AT REUNION',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Parkborough',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129288/Parkborough',
                      data: 'PARKBOROUGH',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Park-Way-Condominiums',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130059/Park-Way-Condominiums',
                      data: 'PARK WAY CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Park-Villas',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129336/Park-Villas',
                      data: 'PARK VILLAS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Park-View',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128454/Park-View',
                      data: 'PARK VIEW',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Park-View-Terrace',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131503/Park-View-Terrace',
                      data: 'PARK VIEW TERRACE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Park-View-ridge',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129757/Park-View-Ridge',
                      data: 'PARK VIEW RIDGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Park-View-meadows',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128305/Park-View-Meadows',
                      data: 'PARK VIEW MEADOWS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Park-View-estate',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128641/Park-View-Estates',
                      data: 'PARK VIEW ESTATES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Park-Place-Villas',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129721/Park-Place-Villas',
                      data: 'PARK PLACE VILLAS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Park-East',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128771/Park-East',
                      data: 'PARK EAST',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Park-Avenue',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131960/Park-Avenue',
                      data: 'PARK AVENUE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Orchard-Valley',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131582/Orchard-Valley',
                      data: 'ORCHARD VALLEY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Oliveglen-Village',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130055/Oliveglen-Village',
                      data: 'OLIVEGLEN VILLAGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Olde-Towne',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127180/Olde-Towne',
                      data: 'OLDE TOWNE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Oakland-Court',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/126994/Oakland-Court',
                      data: 'OAKLAND COURT',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-norwood-park',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130165/Norwood-Park',
                      data: 'NORWOOD PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-northwest-aurora',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127707/Northwest-Aurora',
                      data: 'NORTHWEST AURORA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-northeast',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129833/Northeast',
                      data: 'NORTHEAST',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-North-Range-Village',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131570/North-Range-Village',
                      data: 'NORTH RANGE VILLAGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-North-Range-town',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129172/North-Range-Town-Center',
                      data: 'NORTH RANGE TOWN CENTER',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-nortfolk-one',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131415/Norfolk-Glen',
                      data: 'NORFOLK GLEN',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-nortfolk-two',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129148/Norfolk-Glen',
                      data: 'NORFOLK GLEN',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-1',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/126872/New-World-West',
                      data: 'NEW WORLD WEST',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-2',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130421/New-England-Heights',
                      data: 'NEW ENGLAND HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-3',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127948/Nevin-Village-Green',
                      data: 'NEVIN VILLAGE GREEN',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-4',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128072/Nantucket',
                      data: 'NANTUCKET',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-5',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127904/Murphy-Creek',
                      data: 'MURPHY CREEK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-6',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128394/Mowrey',
                      data: 'MOWREY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-7',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131638/Mountainview-Estates',
                      data: 'MOUNTAINVIEW ESTATES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-8',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130325/Morris-Heights',
                      data: 'MORRIS HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-9',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131196/Moon-Shadow-at-Saddle-Rock',
                      data: 'MOON SHADOW AT SADDLE ROCK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-10',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131478/Monaco',
                      data: 'MONACO',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-11',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132135/Monaco-Vista',
                      data: 'MONACO VISTA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-12',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130142/Mission-Viejo',
                      data: 'MISSION VIEJO',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-13',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129480/Mill-Valley',
                      data: 'MILL VALLEY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-14',
                      data: 'MILL RUN',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132541/Mill-Run',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-15',
                      data: 'MESA',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129498/Mesa',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-16',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129421/Memory-Lane-Gardens',
                      data: 'MEMORY LANE GARDENS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-17',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128984/Meadow-Wood',
                      data: 'MEADOW WOOD',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-18',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127803/Meadow-Point-Condominiums',
                      data: 'MEADOW POINT CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-19',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130199/Meadow-Hills',
                      data: 'MEADOW HILLS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-20',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129614/Manchester-Park',
                      data: 'MANCHESTER PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-21',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128026/Mabry',
                      data: 'MABRY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-22',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129016/Lynwood-Heights',
                      data: 'LYNWOOD HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-23',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130944/Lynn-Knoll',
                      data: 'LYNN KNOLL',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-24',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131442/Lynknoll',
                      data: 'LYNKNOLL',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-25',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131076/Lyn-Meadows',
                      data: 'LYN MEADOWS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-26',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130600/Lyn-Acres',
                      data: 'LYN ACRES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-27',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127666/Lydeva-Heights',
                      data: 'LYDEVA HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-28',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132107/Louisiana-Purchase-II',
                      data: 'LOUISIANA PURCHASE II',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-29',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127935/Linden',
                      data: 'LINDEN',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132449/Lexington',
                      data: 'LEXINGTON',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-30',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127752/Lexington-East',
                      data: 'LEXINGTON EAST',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-31',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131492/Leisure-Landing',
                      data: 'LEISURE LANDING',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-32',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132069/Legacy-Park',
                      data: 'LEGACY PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-33',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131077/Le-Chateau',
                      data: 'LE CHATEAU',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-34',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127332/Lazy-Hills-Ranchettes',
                      data: 'LAZY HILLS RANCHETTES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-35',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132540/Laredo-Highline',
                      data: 'LAREDO HIGHLINE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-36',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130352/Lakeview-Terrace',
                      data: 'LAKEVIEW TERRACE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-37',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127748/Lakeshore',
                      data: 'LAKESHORE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-38',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130641/Lake-Pointe',
                      data: 'LAKE POINTE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-39',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127778/Lake-Estates',
                      data: 'LAKE ESTATES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-40',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129590/La-Vista',
                      data: 'LA VISTA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-41',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129507/Kragelund-Acres',
                      data: 'KRAGELUND ACRES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-42',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127885/Kirkegaard-Acres',
                      data: 'KIRKEGAARD ACRES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-43',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127378/Kirkegaard-Acres',
                      data: 'KIRKEGAARD ACRES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-44',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127565/Kingsborough',
                      data: 'KINGSBOROUGH',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-45',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127627/Kingsborough',
                      data: 'KINGSBOROUGH',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-46',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153826935/kennedy',
                      data: 'KENNEDY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-47',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153826934/kennedy',
                      data: 'KENNEDY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-48',
                      data: 'KEMP',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153826933/kemp',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-49',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132110/Kearney-Heights',
                      data: 'KEARNEY HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-50',
                      data: 'KAREN PARK',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153826931/karen-park',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-51',
                      data: 'JUDD',
                      url: 'https://www.colohomesforsale.com/neighborhoods/153826930/judd',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-52',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128035/Jamaica-Park',
                      data: 'JAMAICA PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-53',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130961/Jackson-Farm',
                      data: 'JACKSON FARM',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-54',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131273/Ivy-Hill',
                      data: 'IVY HILL',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-55',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130918/Irondale',
                      data: 'IRONDALE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-56',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132382/Irondale-Gardens',
                      data: 'IRONDALE GARDENS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-57',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129281/Innsbruck',
                      data: 'INNSBRUCK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-58',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132223/Imperial-Park',
                      data: 'IMPERIAL PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-59',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131104/Iliff-Homes',
                      data: 'ILIFF HOMES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-60',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131253/Iliff-Commons',
                      data: 'ILIFF COMMONS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-61',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132259/Horseshoe-Park',
                      data: 'HORSESHOE PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-62',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132074/Horizons',
                      data: 'HORIZONS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-63',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128411/Holly-Heights',
                      data: 'HOLLY HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-64',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131075/Hoffman-Heights-/-Jewell-Heights',
                      data: 'HOFFMAN HEIGHTS / JEWELL HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-65',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127301/Hillside',
                      data: 'HILLSIDE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-66',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128439/Hillcrest-Villages',
                      data: 'HILLCREST VILLAGES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-67',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127761/Hill-Top',
                      data: 'HILL TOP',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-68',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129863/Highpoint',
                      data: 'HIGHPOINT',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-69',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132386/Highline-Villages',
                      data: 'HIGHLINE VILLAGES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-70',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130313/Highline-Court',
                      data: 'HIGHLINE COURT',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-71',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129583/Highline-/-Expo',
                      data: 'HIGHLINE / EXPO',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-72',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131535/Highlands-at-Buffalo-Run-East',
                      data: 'HIGHLANDS AT BUFFALO RUN EAST',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-73',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127361/Highland-Park',
                      data: 'HIGHLAND PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-74',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131361/High-Pointe',
                      data: 'HIGH POINTE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-75',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127546/High-Hollows-Condominiums',
                      data: 'HIGH HOLLOWS CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-76',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129998/Hidden-Valley',
                      data: 'HIDDEN VALLEY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-77',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128521/Heritage-Eagle-Bend',
                      data: 'HERITAGE EAGLE BEND',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-78',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131865/Heather-Ridge',
                      data: 'HEATHER RIDGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-79',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129792/Heather-Ridge-South',
                      data: 'HEATHER RIDGE SOUTH',
                    },

                    {
                      id: 'ugljan-footer-neighborhoods-item-80',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127892/Heather-Gardens',
                      data: 'HEATHER GARDENS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-81',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132139/Hearthstone',
                      data: 'HEARTHSTONE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-82',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130079/Hawthorn',
                      data: 'HAWTHORN',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-83',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132567/Havana',
                      data: 'HAVANA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-84',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130040/Havana-Villa',
                      data: 'HAVANA VILLA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-85',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128663/Havana-Park',
                      data: 'HAVANA PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-86',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128115/Havana-Heights',
                      data: 'HAVANA HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-87',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127600/Harvest-Meadows',
                      data: 'HARVEST MEADOWS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-88',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130376/Hampden-Villas',
                      data: 'HAMPDEN VILLAS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-89',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127417/Hampden-Towncenter',
                      data: 'HAMPDEN TOWNCENTER',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-90',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128228/Hampden-Hills',
                      data: 'HAMPDEN HILLS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-91',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128616/Hallcrafts-Village-East',
                      data: 'HALLCRAFTS VILLAGE EAST',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-92',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127857/Gun-Club-Estates',
                      data: 'GUN CLUB ESTATES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-93',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129413/Grout',
                      data: 'GROUT',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-94',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127670/Greenfield',
                      data: 'GREENFIELD',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-95',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128438/Greenfield-Masters',
                      data: 'GREENFIELD MASTERS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-96',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128991/Greenbrook',
                      data: 'GREENBROOK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-97',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127030/Granby-Commons',
                      data: 'GRANBY COMMONS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-98',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128172/Grahams',
                      data: 'GRAHAMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-99',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130467/Gilligan',
                      data: 'GILLIGAN',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-100',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129691/Gifford-Gardens',
                      data: 'GIFFORD GARDENS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-101',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129503/GentryCountry-Homes',
                      data: 'GENTRYCOUNTRY HOMES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-102',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127773/Gateway-Townhomes',
                      data: 'GATEWAY TOWNHOMES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-103',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129710/Gateway-Park',
                      data: 'GATEWAY PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-104',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132015/Garden-Heights',
                      data: 'GARDEN HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-105',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129840/Gamble',
                      data: 'GAMBLE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-106',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129547/Gamble-Derby',
                      data: 'GAMBLE DERBY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-107',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129130/Fronterra-Village',
                      data: 'FRONTERRA VILLAGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-108',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127961/Fronterra-Village-Townhomes',
                      data: 'FRONTERRA VILLAGE TOWNHOMES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-109',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128697/Friendly-Village',
                      data: 'FRIENDLY VILLAGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-110',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129563/French-Creek',
                      data: 'FRENCH CREEK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-111',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127625/Foxton-Village',
                      data: 'FOXTON VILLAGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-112',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131388/Foxfield',
                      data: 'FOXFIELD',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-113',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129579/Foxdale-Condominiums',
                      data: 'FOXDALE CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-114',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131365/Fox-Hill',
                      data: 'FOX HILL',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-115',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128293/Fountain-Side',
                      data: 'FOUNTAIN SIDE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-116',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128248/Fitzsimons',
                      data: 'FITZSIMONS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-117',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127168/First-Creek-Farm',
                      data: 'FIRST CREEK FARM',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-118',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132201/Finkbeiner',
                      data: 'FINKBEINER',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-119',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128823/Fernald-/-Frei-Crossing',
                      data: 'FERNALD / FREI CROSSING',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-120',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132116/Fairway',
                      data: 'FAIRWAY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-121',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129796/Fairway-Village',
                      data: 'FAIRWAY VILLAGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-122',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128129/Fairfield-Village',
                      data: 'FAIRFIELD VILLAGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-123',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128482/Fairfax-Park',
                      data: 'FAIRFAX PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-124',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127076/Eureka',
                      data: 'EUREKA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-125',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128890/Estancia',
                      data: 'ESTANCIA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-126',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127742/Embarcadero-in-Willowridge',
                      data: 'EMBARCADERO IN WILLOWRIDGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-127',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132046/Elk-Ridge',
                      data: 'ELK RIDGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-128',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128095/Eastwood-Townhomes',
                      data: 'EASTWOOD TOWNHOMES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-129',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131432/East-Ridge-/-Ptarmigan-Park',
                      data: 'EAST RIDGE / PTARMIGAN PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-130',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128979/East-Quincy-Highlands',
                      data: 'EAST QUINCY HIGHLANDS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-131',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128694/East-Colfax',
                      data: 'EAST COLFAX',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-132',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/126982/Eagle-Village',
                      data: 'EAGLE VILLAGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-133',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128800/Eagle-Creek',
                      data: 'EAGLE CREEK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-134',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127968/Dupont',
                      data: 'DUPONT',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-135',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/126907/Dover',
                      data: 'DOVER',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-136',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130191/Dove-Hill',
                      data: 'DOVE HILL',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-137',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129143/Derby',
                      data: 'DERBY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-138',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131787/Denver-International-Tech-Center',
                      data: 'DENVER INTERNATIONAL TECH CENTER',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-139',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129581/Denver-International-Airport',
                      data: 'DENVER INTERNATIONAL AIRPORT',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-140',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131171/Del-Mar',
                      data: 'DEL MAR',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-141',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131949/Del-Mar-Parkway',
                      data: 'DEL MAR PARKWAY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-142',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131651/Del-Mar-Park-Condominiums',
                      data: 'DEL MAR PARK CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-143',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128919/Deer-Pointe-Village-Condominiums',
                      data: 'DEER POINTE VILLAGE CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-144',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128166/Daybreak',
                      data: 'DAYBREAK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-145',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128854/Dam-East-/-West',
                      data: 'DAM EAST / WEST',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-146',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129065/Cutler',
                      data: 'CUTLER',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-147',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128168/Crown-Point',
                      data: 'CROWN POINT',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-148',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128867/Cross-Creek',
                      data: 'CROSS CREEK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-149',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131645/Crestridge',
                      data: 'CRESTRIDGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-150',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129500/Creekview',
                      data: 'CREEKVIEW',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-151',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128675/Creekside-Eagle-Bend',
                      data: 'CREEKSIDE EAGLE BEND',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-152',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128323/Coyote-Ranch',
                      data: 'COYOTE RANCH',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-153',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127782/Country-Village',
                      data: 'COUNTRY VILLAGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-154',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131661/Country-Lane',
                      data: 'COUNTRY LANE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-155',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132200/Country-Green',
                      data: 'COUNTRY GREEN',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-156',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132411/Country-Club-Village',
                      data: 'COUNTRY CLUB VILLAGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-157',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131589/Cottonwood',
                      data: 'COTTONWOOD',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-158',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129598/Copperleaf',
                      data: 'COPPERLEAF',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-159',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129094/Copper-Ridge',
                      data: 'COPPER RIDGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-160',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129523/Conter-Estates',
                      data: 'CONTER ESTATES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-161',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128126/Conservatory',
                      data: 'CONSERVATORY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-162',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127486/Columbine-Ranches',
                      data: 'COLUMBINE RANCHES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-163',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129502/Colfax-Villa',
                      data: 'COLFAX VILLA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-164',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129023/Cobblewood',
                      data: 'COBBLEWOOD',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-165',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129677/Clermont',
                      data: 'CLERMONT',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-166',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128915/Churchill',
                      data: 'CHURCHILL',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-167',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131124/Cherry',
                      data: 'CHERRY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-168',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132543/Cherry-Grove-East',
                      data: 'CHERRY GROVE EAST',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-169',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131366/Cherry-Creek-Rancho',
                      data: 'CHERRY CREEK RANCHO',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-170',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127097/Cherry-Creek-Racquet-Club',
                      data: 'CHERRY CREEK RACQUET CLUB',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-171',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127080/Cherry-Creek-East',
                      data: 'CHERRY CREEK EAST',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-172',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130801/Cherry-Brook',
                      data: 'CHERRY BROOK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-173',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131255/Cherokee',
                      data: 'CHEROKEE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-174',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127470/Chenango',
                      data: 'CHENANGO',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-175',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128140/Chelsea',
                      data: 'CHELSEA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-176',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132131/Chelsea-Park',
                      data: 'CHELSEA PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-177',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129240/Charleston-Place-Condominiums',
                      data: 'CHARLESTON PLACE CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-178',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130700/Chapparal',
                      data: 'CHAPPARAL',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-179',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127769/Chapman',
                      data: 'CHAPMAN',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-180',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129560/Champagne',
                      data: 'CHAMPAGNE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-181',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129587/Chambers-Ridge',
                      data: 'CHAMBERS RIDGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-182',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128235/Chambers-Heights',
                      data: 'CHAMBERS HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-183',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128066/Chambers-Estates',
                      data: 'CHAMBERS ESTATES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-184',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131935/Chaddsford',
                      data: 'CHADDSFORD',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-185',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128628/Century-City-Condominiums',
                      data: 'CENTURY CITY CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-186',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130356/Centretech',
                      data: 'CENTRETECH',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-187',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127528/Central',
                      data: 'CENTRAL',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-188',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132094/Central-Park-Townhomes',
                      data: 'CENTRAL PARK TOWNHOMES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-189',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127014/Center-Pointe',
                      data: 'CENTER POINTE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-190',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129532/Cedar-Cove',
                      data: 'CEDAR COVE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-191',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132404/Carriage-Village',
                      data: 'CARRIAGE VILLAGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-192',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128862/Carriage-Place',
                      data: 'CARRIAGE PLACE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-193',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128501/Carnation-Meadows',
                      data: 'CARNATION MEADOWS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-194',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129604/Burns-Aurora',
                      data: 'BURNS AURORA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-195',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128494/Buffalo-Mesa',
                      data: 'BUFFALO MESA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-196',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132409/Buffalo-Highlands',
                      data: 'BUFFALO HIGHLANDS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-197',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127273/Buckley-Ranch',
                      data: 'BUCKLEY RANCH',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-198',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131201/Buckley-Air-Force-Base',
                      data: 'BUCKLEY AIR FORCE BASE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-199',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/126916/Buckingham-Oaks-Condominiums',
                      data: 'BUCKINGHAM OAKS CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-200',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128565/Brookvale',
                      data: 'BROOKVALE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-201',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131907/Brookhaven-Condominiums',
                      data: 'BROOKHAVEN CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-202',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130071/Broadstone-Cornerstone',
                      data: 'BROADSTONE CORNERSTONE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-203',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128056/Brittany-Highlands',
                      data: 'BRITTANY HIGHLANDS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-204',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127123/Briarwood',
                      data: 'BRIARWOOD',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-205',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129189/Breakaway-Condominiums',
                      data: 'BREAKAWAY CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-206',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129457/Brandychase',
                      data: 'BRANDYCHASE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-207',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129635/Brandychase-Condominiums',
                      data: 'BRANDYCHASE CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-208',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132256/Brandon-Park-Condominiums',
                      data: 'BRANDON PARK CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-209',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128186/Bradell-Estates',
                      data: 'BRADELL ESTATES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-210',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131488/Bowen',
                      data: 'BOWEN',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-211',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127890/Bogner',
                      data: 'BOGNER',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-212',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132600/Blackstone',
                      data: 'BLACKSTONE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-213',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129888/Black-Hawk-Derby',
                      data: 'BLACK HAWK DERBY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-214',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127416/Beverly-Homes',
                      data: 'BEVERLY HOMES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-215',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128337/Bestview-Acres',
                      data: 'BESTVIEW ACRES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-216',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128648/Berkshire',
                      data: 'BERKSHIRE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-217',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127428/Berkshire-Place',
                      data: 'BERKSHIRE PLACE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-218',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132138/Bel-Aire-Estates',
                      data: 'BEL-AIRE ESTATES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-219',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129568/Beacon-Point',
                      data: 'BEACON POINT',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-220',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130753/Bayberry-Condominiums',
                      data: 'BAYBERRY CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-221',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127753/Barr-Lake-Estates',
                      data: 'BARR LAKE ESTATES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-222',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129215/Banyon-Hollow-Condominiums',
                      data: 'BANYON HOLLOW CONDOMINIUMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-223',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131528/Avalon',
                      data: 'AVALON',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-224',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130908/Aurora-Vista',
                      data: 'AURORA VISTA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-225',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132129/Aurora-Vista',
                      data: 'AURORA VISTA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-226',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130104/Aurora-Meadows',
                      data: 'AURORA MEADOWS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-227',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127058/Aurora-Knolls',
                      data: 'AURORA KNOLLS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aurora-Knolls-South',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127466/Aurora-Knolls-South',
                      data: 'AURORA KNOLLS SOUTH',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Hutchinson-Heights',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131050/Aurora-Knolls-/-Hutchinson-Heights',
                      data: 'AURORA KNOLLS / HUTCHINSON HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aurora-Hills',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130577/Aurora-Hills',
                      data: 'AURORA HILLS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aurora-Hills-Golf-Course',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127443/Aurora-Hills-Golf-Course',
                      data: 'AURORA HILLS GOLF COURSE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aurora-Highlands',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127373/Aurora-Highlands',
                      data: 'AURORA HIGHLANDS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aurora-Highlands',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132215/Aurora-Highlands',
                      data: 'AURORA HIGHLANDS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aurora-Heights',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130152/Aurora-Heights',
                      data: 'AURORA HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aurora-East-Village',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130359/Aurora-East-Village',
                      data: 'AURORA EAST VILLAGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aurora-East-Planned-Community',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127931/Aurora-East-Planned-Community',
                      data: 'AURORA EAST PLANNED COMMUNITY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aurora-City-Center',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128781/Aurora-City-Center',
                      data: 'AURORA CITY CENTER',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aurora-City-Center-North',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128443/Aurora-City-Center-North',
                      data: 'AURORA CITY CENTER NORTH',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aurora-Cascades',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131233/Aurora-Cascades',
                      data: 'AURORA CASCADES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Auburn-Hill',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127943/Auburn-Hill',
                      data: 'AUBURN HILL',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aspen-ridge',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128321/Aspen-Ridge',
                      data: 'ASPEN RIDGE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aspen-hills',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127791/Aspen-Hills',
                      data: 'ASPEN HILLS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aspen-Creek',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/126893/Aspen-Creek',
                      data: 'ASPEN CREEK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Asbury-Townhomes',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130110/Asbury-Townhomes',
                      data: 'ASBURY TOWNHOMES',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Arapahoe-Meadows',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127671/Arapahoe-Meadows',
                      data: 'ARAPAHOE MEADOWS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Arapahoe-Heights',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128271/Arapahoe-Heights',
                      data: 'ARAPAHOE HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Appletree-East',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131560/Appletree-East',
                      data: 'APPLETREE EAST',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Apache-Mesa',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130713/Apache-Mesa',
                      data: 'APACHE MESA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Apache-Meadows',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128878/Apache-Meadows',
                      data: 'APACHE MEADOWS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Antelope',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129831/Antelope',
                      data: 'ANTELOPE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Andover-Glen-at-Chery-Creek',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131402/Andover-Glen-at-Chery-Creek',
                      data: 'ANDOVER GLEN AT CHERY CREEK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Anderson',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127780/Anderson',
                      data: 'ANDERSON',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Americana',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130160/Americana',
                      data: 'AMERICANA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Alvarado-Place',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131649/Alvarado-Place',
                      data: 'ALVARADO PLACE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Allied',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131484/Allred',
                      data: 'ALLRED',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Allied-Waste',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132090/Allied-Waste',
                      data: 'ALLIED WASTE',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Albeck',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/127399/Albeck',
                      data: 'ALBECK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Advance-Mobile-Park',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128170/Advance-Mobile-Park',
                      data: 'ADVANCE MOBILE PARK',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Adonea',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/132096/Adonea',
                      data: 'ADONEA',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Adams-Heights',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130867/Adams-Heights',
                      data: 'ADAMS HEIGHTS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Adams-City',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128589/Adams-City',
                      data: 'ADAMS CITY',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Adams-City-Poultry-Farms',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/131794/Adams-City-Poultry-Farms',
                      data: 'ADAMS CITY POULTRY FARMS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Adams-City-Gardens',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/130000/Adams-City-Gardens',
                      data: 'ADAMS CITY GARDENS',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-Aamodt',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/129773/Aamodt',
                      data: 'AAMODT',
                    },
                    {
                      id: 'ugljan-footer-neighborhoods-item-56th-Avenue-Industrial-Park',
                      url: 'https://www.colohomesforsale.com/neighborhood-detail/128277/56th-Avenue-Industrial-Park',
                      data: '56TH AVENUE INDUSTRIAL PARK',
                    },
                  ],
                },
                {
                  id: 'footer-menu-item-sold-listing',
                  data: 'SOLD LISTING',
                  url: 'https://www.colohomesforsale.com/sold-listing',
                },
                {
                  id: 'footer-menu-item-trade-in',
                  data: 'EXCLUSIVE TRADE-IN PROGRAM',
                  url: 'https://trade-in.colohomesforsale.com/',
                },
                {
                  id: 'footer-menu-item-',
                  data: 'CONTACT',
                  url: 'https://www.colohomesforsale.com/contact',
                },
                {
                  id: 'footer-menu-item-cash-offers',
                  data: 'CASH OFFERS',
                  url: 'https://www.colohomesforsale.com/cash-offers',
                },
                {
                  id: 'footer-menu-item-reviews',
                  data: 'REVIEW',
                  url: 'https://www.colohomesforsale.com/reviews',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'main-footer',
      className: 'on-ugljan-footer ugljan-privacy-footer',
      sectionClassName: 'on-ugljan-privacy-footer-container',
      columns: [
        {
          id: 'ugljan-footer-socials-column',
          className: 'on-ugljan-footer-socials-column',
          items: [
            {
              id: 'ugljan-footer-socials',
              type: 'socialIcons',
              className: 'on-ugljan-footer-socials',
              items: [
                {
                  id: 'ugljan-footer-facebook',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook-f',
                  url: 'http://www.facebook.com/propertiesincolorado',
                },
                {
                  id: 'ugljan-footer-linkedin',
                  iconTypeClassName: 'fab',
                  iconClassName: 'linkedin-in',
                  url: 'http://www.linkedin.com/in/%20bob-hryniewich-00b01aa',
                },
                {
                  id: 'ugljan-footer-zillow',
                  className: 'ugljan-footer-zillow',
                  iconUrl: 'https://si-homelight.s3.amazonaws.com/sites/hvar/zillow.svg',
                  url: 'https://www.zillow.com/profile/Bob-Hryniewich/',
                },
              ],
            },
          ],
        },
        {
          id: 'ugljan-footer-disclaimer-column',
          className: 'on-ugljan-footer-disclaimer-column',
          items: [
            {
              id: 'ugljan-footer-recolorado-logo',
              type: 'image',
              className: 'on-ugljan-colorado-image',
              // url: 'https://si-homelight.s3.amazonaws.com/sites/ugljan/logo-footer-colorado.webp',
              url: 'https://cdn.chime.me/image/fs/cmsbuild/20201210/11/w2800_original_c3c0a596-4cda-412a-9287-bf1f57e06229.png',
            },
            {
              id: 'ugljan-footer-disclaimer-heading-one',
              type: 'itemContent',
              style: { marginBottom: '15px' },
              items: [
                {
                  content: 'IDX DISCLAIMER. ',
                  url: '/',
                  className: 'ugljan-non-clickable',
                },
                {
                  content:
                    'The IDX Subscriber Site shall include the following IDX Disclaimer on every page or screen of the IDX Subscriber’s Web site and/or IDX Associate Subscriber’s Web site in which IDX Content is displayed:',
                },
              ],
            },

            {
              id: 'ugljan-footer-disclaimer-heading-two',
              type: 'heading',
              elementType: 'p',
              style: { marginBottom: '15px' },
              data: '“The content relating to real estate for sale in this Web site comes in part from the Internet Data eXchange (IDX) program of METROLIST, INC., DBA RECOLORADO. Real estate listings held by brokers other than RE/MAX Masters Millennium are marked with the IDX Logo. This information is being provided for the consumers’ personal, non-commercial use and may not be used for any other purpose. All information subject to change and should be independently verified.”',
            },
            {
              id: 'ugljan-footer-disclaimer-heading-one',
              type: 'itemContent',
              style: { marginBottom: '15px' },
              items: [
                {
                  content: 'DISCLAIMERS AND COPYRIGHT LANGUAGE. VENDOR ',
                  url: '/',
                  className: 'ugljan-non-clickable',
                },
                {
                  content:
                    'shall display the following notices on every page or screen of the IDX Subscriber’s Web site and/or IDX Associate Subscriber’s Web site in which IDX Content is displayed.',
                },
              ],
            },

            {
              id: 'ugljan-footer-disclaimer-heading-four',
              type: 'heading',
              elementType: 'p',
              style: { marginBottom: '15px' },
              data: '1. This publication is designed to provide information regarding the subject matter covered. It is displayed with the understanding that the publisher and authors are not engaged in rendering real estate, legal, accounting, tax, or other professional services and that the publisher and authors are not offering such advice in this publication. If real estate, legal, or other expert assistance is required, the services of a competent, professional person should be sought.',
            },
            {
              id: 'ugljan-footer-disclaimer-heading-five',
              type: 'heading',
              elementType: 'p',
              style: { marginBottom: '15px' },
              data: '2. The information contained in this publication is subject to change without notice. METROLIST, INC., DBA RECOLORADO MAKES NO WARRANTY OF ANY KIND WITH REGARD TO THIS MATERIAL, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. METROLIST, INC., DBA RECOLORADO SHALL NOT BE LIABLE FOR ERRORS CONTAINED HEREIN OR FOR ANY DAMAGES IN CONNECTION WITH THE FURNISHING, PERFORMANCE, OR USE OF THIS MATERIAL.',
            },
            {
              id: 'ugljan-footer-disclaimer-heading-six',
              type: 'heading',
              elementType: 'p',
              data: '3. PUBLISHER’S NOTICE:',
              className: 'mb-0',
            },
            {
              id: 'ugljan-footer-disclaimer-heading-seven',
              type: 'heading',
              elementType: 'p',
              style: { marginBottom: '15px' },
              data: 'All real estate advertised herein is subject to the Federal Fair Housing Act and the Colorado Fair Housing Act, which Acts make it illegal to make or publish any advertisement that indicates any preference, limitation, or discrimination based on race, color, religion, sex, handicap, familial status, or national origin.',
            },
            {
              id: 'ugljan-footer-disclaimer-heading-eight',
              type: 'heading',
              elementType: 'p',
              style: { marginBottom: '15px' },
              data: '4. METROLIST, INC., DBA RECOLORADO will not knowingly accept any advertising for real estate that is in violation of the law. All persons are hereby informed that all dwellings advertised are available on an equal opportunity basis.',
            },
            {
              id: 'ugljan-footer-disclaimer-item-nine',
              type: 'itemContent',
              style: { marginBottom: '15px' },
              items: [
                {
                  content: '5.',
                },
                {
                  imageUrl: 'https://si-homelight.s3.amazonaws.com/sites/ugljan/logo-footer-equal.png',
                  imageClassName: 'ugljan-equal-logo',
                },
              ],
            },
            {
              id: 'ugljan-footer-disclaimer-heading-',
              type: 'heading',
              elementType: 'p',
              style: { marginBottom: '15px' },
              data: '6. 2022 METROLIST, INC., DBA RECOLORADO – All Rights Reserved 6455 S. Yosemite St., Suite 300 Greenwood Village, CO 80111 USA',
            },
            {
              id: 'ugljan-footer-disclaimer-heading-',
              type: 'heading',
              elementType: 'p',
              style: { marginBottom: '60px' },
              data: '7. ALL RIGHTS RESERVED WORLDWIDE. No part of this publication may be reproduced, adapted, translated, stored in a retrieval system or transmitted in any form or by any means, electronic, mechanical, photocopying, recording, or otherwise, without the prior written permission of the publisher. The information contained herein including but not limited to all text, photographs, digital images, virtual tours, may be seeded and monitored for protection and tracking.',
            },
            {
              id: 'ugljan-footer-disclaimer-heading-',
              type: 'heading',
              elementType: 'p',
              style: { marginBottom: '15px' },
              data: 'IDX information is provided exclusively for consumers’ personal, non-commercial use and that it may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Information deemed reliable but not guaranteed to be accurate. Listing information updated daily.',
            },
            {
              id: 'ugljan-footer-disclaimer-item-nine',
              type: 'itemContent',
              style: { paddingBottom: '10px' },
              items: [
                {
                  content: 'Made in Phoenix, AZ by your friends at ',
                },
                {
                  url: 'https://chime.me/',
                  content: ' Chime Technologies',
                },
                {
                  content: ',  Inc. Copyright 2022. All Rights Reserved. ',
                },
                {
                  url: 'https://www.colohomesforsale.com/site/privacy-terms',
                  content: 'Privacy Policy & TOS',
                },
                {
                  content: ' | ',
                },
                {
                  url: 'https://www.colohomesforsale.com/sitemap',
                  content: 'Sitemap',
                },
                {
                  content: ' | ',
                },
                {
                  url: 'https://www.colohomesforsale.com/accessibility',
                  content: 'Accessibility',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {
    'tradeIn.hero.className': 'heroClassName',
    'cashOffer.hero.className': 'heroClassName',
  },
  siteData: {
    agency: 'Bob Hyrniewich',
    agentDescription: 'Top agents in Colorado',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/ugljan/agent.jpeg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/ugljan/hero-bg.png',
    heroClassName: 'on-ugljan-hero',
  },
};
